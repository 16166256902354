<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息弹窗·客户回收站/公海客户
开始时间：2017-11-09
开发人员：陈立珍
最后修改：2019-01-11
备注说明：如需修改请联系开发人员
-->

<template>
	<!--客户回收站/公海客户点击企业名称出现的弹框页面-->
	<div id="moreInfo">
		<div class="crm-moreInfo">
			<el-dialog title="客户详细信息" class='yourName dialogOne' size="tiny" :visible.sync="test" :before-close="close" :modal="false" :modal-append-to-body="false" :lock-scroll="true" :close-on-click-modal="false">
				<!--客户信息-->
				<div>
					<img :src="customer_img" alt="客户头像" class="user-bg" />
					<div class="more-userInfo">
						<h4 class="user-name">
							<el-tooltip class="item" effect="dark" :open-delay="300" :content="customerInformation.customer_name" placement="top-start">
      							<span>{{customerInformation.customer_name}}</span>
							</el-tooltip>
							<span style="color: #999; font-size: 12px;font-weight: normal;">国家：
								<el-tooltip v-if="country_flag != ''"  class="item" effect="dark" :open-delay="300" :content="customerInformation.country" placement="top-start">
									<span class="countryFlag" :class="country_flag" style="width:24px;height:24px;position: relative;top: 3px;"></span>
								</el-tooltip>
								<span v-else>无</span>
							</span>
						</h4>
						<span class="user-type">{{link_name}}
            			<span style="color: #999; font-size: 12px;">{{customerInformation.email}}</span>
						<span style="color: #999; font-size: 12px;margin-left:5px">关联产品：</span>
						  <look-product :choiceProductArr="productIds" v-if="productIdLoading" style="display:inline-block;font-size:12px;"></look-product>
						</span>
						<span class="user-follow">跟进人: {{followup_name}}</span>
					</div>
				</div>
				<!--动态-->
				<el-tabs v-model="activeName" class="tab-content">
					<el-tab-pane label="动态" name="first">
						<div>
							<ul>
								<el-radio-group v-model="dynamicType">
									<li v-for="(tab,index) in tabLists" class="tab-title">
										<el-radio :label="index">{{tab.text}}</el-radio>
									</li>
								</el-radio-group>
							</ul>
							<div class="create-list">
								<!--动态-->
								<ul v-if="dynamicType == 0">
									<div v-if="dynamicList.length > 0">
										<li class="clst-li dt-li" v-for="(dt,$index) in dynamicList">
											<div>
												<div class="life-title-box">
													<img src="../../../../static/img/common/cmer.png" alt="用户头像" class="client-bg" />
													<div class="more-createInfo">
														<p class="create-info">{{dt.username}} 于
															<span class="create-date">{{dt.create_time}}</span>
															<span class="create-follow">更新了动态</span>
														</p>
													</div>
												</div>
												<div class="create-life"><span v-html="dt.dynamic_info"></span></div>
											</div>
										</li>
									</div>
									<div v-else>
										<li class="clst-li dt-li">暂无数据</li>
									</div>
								</ul>
								<!--跟进记录-->
								<!--<ul v-if="dynamicType == 1">
									<div v-if="recordList.length > 0">
										<li class="clst-li dt-li" v-for="(dt,$index) in recordList">
											<div>
												<div class="life-title-box">
													<img src="../../../../static/img/common/cmer.png" alt="用户头像" class="client-bg" />
													<div class="more-createInfo">
														<p class="create-info">{{dt.username}} 于
															<span class="create-date">{{dt.create_date}}</span>
															<span class="create-follow">添加了跟进记录</span>
														</p>
													</div>
												</div>
												<div class="create-life" v-html="dt.followup_content">{{dt.followup_content}}</div>
											</div>
										</li>
									</div>
									<div v-else>
										<li class="clst-li dt-li">暂无数据</li>
									</div>
								</ul>-->
								<!--生命线-->
								<ul v-if="dynamicType == 1">
									<div v-if="lifeList.length > 0">
										<li class="clst-li dt-li" v-for="(dt,$index) in lifeList">
											<div>
												<div class="life-title-box">
													<img src="../../../../static/img/common/cmer.png" alt="用户头像" class="client-bg" />
													<div class="more-createInfo">
														<p class="create-info">{{dt.username}} 于
															<span class="create-date">{{dt.update_time}}</span>
														</p>
													</div>
												</div>
												<div class="create-life">{{dt.operator_name}}</div>
											</div>
										</li>
									</div>
									<div v-else>
										<li class="clst-li dt-li">暂无数据</li>
									</div>
								</ul>
							</div>
						</div>
					</el-tab-pane>
					<!--企业信息-->
					<el-tab-pane label="企业信息" name="second">
						<table width="100%" cellpadding="0" cellspacing="0" class="company-tb-info">
							<tbody>
								<tr>
									<td class="tr-title">基本信息</td>
									<td colspan="3"></td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">企业全称</td>
									<td width="300">{{customerInformation.customer_name}}</td>
									<td class="crm-cu_tbg" width="100">联系人姓名</td>
									<td width="300">{{link_name}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">所在国家</td>
									<td width="300">{{customerInformation.country}}</td>
									<td class="crm-cu_tbg" width="100">联系人邮箱</td>
									<td width="300">{{customerInformation.email}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">首次联系</td>
									<td width="300">{{customerInformation.operate_time}}</td>
									<td class="crm-cu_tbg" width="100">联系人电话</td>
									<td width="300">{{customerInformation.phone}}</td>
								</tr>
								<tr>
									<td class="tr-title">业务信息</td>
									<td colspan="3"></td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">客户分类</td>
									<td width="300">{{classify_name}}</td>
									<td class="crm-cu_tbg" width="100">客户来源</td>
									<td width="300">{{source_name}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">客户关系</td>
									<td width="300">{{relation_name}}</td>
									<td class="crm-cu_tbg" width="100">客户状态</td>
									<td width="300">{{status_name}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">所属行业</td>
									<td width="300">{{hangye_name}}</td>
									<td class="crm-cu_tbg" width="100">关联产品</td>
									<td width="300">
										 <look-product :choiceProductArr="productIds" v-if="productIdLoading" ></look-product>
									</td>
								</tr>
								<tr>
									<td class="tr-title">自定义字段</td>
									<td colspan="3"></td>
								</tr>
								<tr v-for="field in cusFieldList">
									<td class="crm-cu_tbg" width="100">名称</td>
									<td width="300">{{field.name}}</td>
									<td class="crm-cu_tbg" width="100">对应值</td>
									<td width="300">{{field.value}}</td>
								</tr>
								<tr>
									<td class="tr-title">其它信息</td>
									<td colspan="3"></td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">省份/州/区</td>
									<td width="300">{{customerInformation.province}}</td>
									<td class="crm-cu_tbg" width="100">城市</td>
									<td width="300">{{customerInformation.city}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">企业简称</td>
									<td width="300">{{customerInformation.simp_name}}</td>
									<td class="crm-cu_tbg" width="100">员工规模</td>
									<td width="300">{{customerInformation.persons_scale}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">手机</td>
									<td width="300">{{customerInformation.mobile}}</td>
									<td class="crm-cu_tbg" width="100">职位</td>
									<td width="300">{{customerInformation.post}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">传真</td>
									<td width="300">{{customerInformation.fax}}</td>
									<td class="crm-cu_tbg" width="100">网址</td>
									<td width="300">{{customerInformation.website}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg">地址</td>
									<td colspan="3">{{customerInformation.address}}</td>
								</tr>
								<tr>
									<td class="tr-title">社交信息</td>
									<td colspan="3"></td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">Facebook</td>
									<td width="300">{{customerInformation.facebook}}</td>
									<td class="crm-cu_tbg" width="100">Twitter</td>
									<td width="300">{{customerInformation.twitter}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">LinkedIn</td>
									<td width="300">{{customerInformation.linkedin}}</td>
									<td class="crm-cu_tbg" width="100">Pinterest</td>
									<td width="300">{{customerInformation.pinterest}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">Google+</td>
									<td width="300">{{customerInformation.google}}</td>
									<td class="crm-cu_tbg" width="100">Tumblr</td>
									<td width="300">{{customerInformation.tumblr}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">Instagram</td>
									<td width="300">{{customerInformation.instagram}}</td>
									<td class="crm-cu_tbg" width="100">VK</td>
									<td width="300">{{customerInformation.vk}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">Made In China</td>
									<td width="300">{{customerInformation.make_in_china}}</td>
									<td class="crm-cu_tbg" width="100">Alibaba</td>
									<td width="300">{{customerInformation.alibaba}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">Skype</td>
									<td width="300">{{customerInformation.skype}}</td>
									<td class="crm-cu_tbg" width="100">Youtube</td>
									<td width="300">{{customerInformation.youtube}}</td>
								</tr>
							</tbody>
						</table>
					</el-tab-pane>
					<!--联系人-->
					<el-tab-pane label="联系人" name="third">
						<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%">
							<tbody>
								<tr>
									<th class="th" width="5%"></th>
									<th class="th" width="15%">姓名</th>
									<th class="th" width="20%">联系邮箱</th>
									<th class="th" width="15%">建立时间</th>
									<th class="th" width="15%">电话</th>
									<th class="th" width="15%">手机</th>
									<th class="th" width="5%">查看</th>
								</tr>
								<tr v-for="tab in linkmanList" v-if="linkmanList.length > 0">
									<td width="5%" class="td spot-box">
										<span class="spot" v-if="tab.is_main==1" title="主联系人"></span>
									</td>
									<td class="td cnt-name" width="15%">
										<div class="max-width-2 limit-show">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.name" placement="top-start">
												<span>{{tab.name}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="20%">
										<div class="max-width-1 limit-show">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.email" placement="top-start">
												<span>{{tab.email}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="15%">{{tab.date}}</td>
									<td class="td cnt-name" width="15%">
										<span class="read-cnt_name" v-if="tab.tel_num>1" @click="lookManyPhone(tab.tel)" title="点击查看">多个</span>
										<span v-if="tab.tel_num==1">{{tab.tel}}</span>
										<span v-if="tab.tel_num==0"></span>
									</td>
									<td class="td" width="15%">
										<div class="max-width-2 limit-show">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.phone" placement="top-start">
												<span>{{tab.phone}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td cnt-name" width="5%">
										<span class="read-cnt_name" @click="lookLinkMan(tab.linkman_id)">
      										<i class="icon iconfont icon-eyes read-cnt_name"></i>
                						</span>
									</td>
								</tr>
								<tr v-if="linkmanList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
							</tbody>
						</table>
						<!--分页-->
						<el-pagination v-if="linkmanList.length>0" class="paging" :current-page.sync="linkman_currpage" :page-size="5" :total="linkman_tolRecord" layout="total,prev, pager, next" :page-count='linkman_tolpage' @current-change='getLinkManList()'>
						</el-pagination>
					</el-tab-pane>
					<el-tab-pane label="采购背景" name="ten" class="company-info" style="height: 290px;overflow: auto;">
						<table width="100%" cellpadding="0" cellspacing="0" class="company-tb-info" v-loading="autographLoading" element-loading-text="拼命加载中">
							<tbody>
								<tr>
									<td class="crm-cu_tbg" width="100">成立时间</td>
									<td>{{cusR_backgroundInfos.build_time}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">商业类型</td>
									<td>{{cusR_backgroundInfos.business_type}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">销售平台</td>
									<td>{{cusR_backgroundInfos.sales_platform}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">主营产品</td>
									<td>{{cusR_backgroundInfos.main_pro}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">经营地址</td>
									<td>{{cusR_backgroundInfos.business_addr}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">公司简介</td>
									<td>{{cusR_backgroundInfos.about_us}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">年采购额</td>
									<td>{{cusR_purchaseInfos.purchase_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">采购目的</td>
									<td>{{cusR_purchaseInfos.purchase_purpose}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">平均采购频率</td>
									<td>{{cusR_purchaseInfos.purchase_frequency}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">偏好类型</td>
									<td>{{cusR_purchaseInfos.supplier_type}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="100">偏好行业</td>
									<td>{{cusR_purchaseInfos.preferences_industry}}</td>
								</tr>
							</tbody>
						</table>
					</el-tab-pane>
					<el-tab-pane v-if="isAlibabaOpen" label="阿里数据" name="eleven" class="company-info" style="height: 300px;overflow: auto;">
						<table width="100%" cellpadding="0" cellspacing="0" class="company-tb-info" v-loading="autographLoading" element-loading-text="拼命加载中">
							<tbody>
								<tr>
									<td class="crm-cu_tbg" width="200">加入Alibaba.com时间</td>
									<td>{{cusR_joinAliTime}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">询盘产品</td>
									<td>{{cusR_xunpanPro}}</td>
								</tr>
								<!--<tr>
									<td class="tr-title" colspan="2"><b>采购信息</b></td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">年采购额</td>
									<td>{{cusR_purchaseInfos.purchase_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">采购目的</td>
									<td>{{cusR_purchaseInfos.purchase_purpose}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">平均采购频率</td>
									<td>{{cusR_purchaseInfos.purchase_frequency}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">偏好类型</td>
									<td>{{cusR_purchaseInfos.supplier_type}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">偏好行业</td>
									<td>{{cusR_purchaseInfos.preferences_industry}}</td>
								</tr>-->
								<tr>
									<td class="tr-title" colspan="2"><b>最近90天行为数据(alibaba.com)</b></td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">注册年限</td>
									<td>{{cusR_purchaseInfos.register_year}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">登录天数</td>
									<td>{{cusR_purchaseInfos.login_day}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">产品浏览数</td>
									<td>{{cusR_purchaseInfos.pro_view}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">搜索次数</td>
									<td>{{cusR_purchaseInfos.search_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">有效询盘数/回复询盘数</td>
									<td>{{cusR_purchaseInfos.valid_xunpan_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">被标为垃圾询盘数</td>
									<td>{{cusR_purchaseInfos.rubbish_xunpan_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">有效RFQ数</td>
									<td>{{cusR_purchaseInfos.RFQ_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">收到报价数/查看报价数</td>
									<td>{{cusR_purchaseInfos.offer_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">被添加联系人数</td>
									<td>{{cusR_purchaseInfos.add_concact_count}}</td>
								</tr>
								<tr>
									<td class="crm-cu_tbg" width="200">被添加黑名单数</td>
									<td>{{cusR_purchaseInfos.add_blacklist}}</td>
								</tr>
								<tr>
									<td class="tr-title" colspan="2"><b>最近搜索词</b></td>
								</tr>
								<tr>
									<td colspan="2">{{cusR_purchaseInfos.recent_searches}}</td>
								</tr>
								<tr>
									<td class="tr-title" colspan="2"><b>最常采购行业</b></td>
								</tr>
								<tr>
									<td colspan="2">{{cusR_purchaseInfos.re_purchase_industry}}</td>
								</tr>
							</tbody>
						</table>
						<p class="need_p" style="font-size: 12px;padding-left: 5px;"><b>最新采购需求</b></p>
						<ul>
							<li v-for="need in cusR_purchaseNeedList" class="need_p">
								<span>{{need}}</span>
							</li>
							<li v-if="cusR_purchaseNeedList.length == 0" class="need_p">
								<span>没有</span>
							</li>
						</ul>
						<p class="need_p" style="font-size: 12px;padding-left: 5px;"><b>最近询盘产品</b></p>
						<div v-for="img in cusR_imgList" class="img_div">
							<a :href="img" target="_blank"><img :src="img" width="100" height="100" /></a>
						</div>
						<div v-if="cusR_imgList.length == 0" class="need_p">
							<span>没有</span>
						</div>
					</el-tab-pane>
					<!--邮件-->
					<el-tab-pane label="邮件" name="sixth">
						<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%" v-loading="autographLoading" element-loading-text="拼命加载中">
							<tbody>
								<tr>
									<td colspan="100%" class="connect-title">
										<div class="tab-button">
											<el-radio-group v-model="radio3">
												<span @click="emailShow=true"><el-radio :label="1" >收件箱</el-radio></span>
												<span @click="emailShow=false"><el-radio :label="2" >发件箱</el-radio></span>
											</el-radio-group>
											<select v-model="cusr_selectMailAccount" @change="cusrMailAccountChange()">
												<option v-for="item in cusr_mailAccountList" :value="item.account_id">{{item.mail_account}}</option>
											</select>
										</div>
									</td>
								</tr>
								<!--收件箱-->
								<tr v-if="emailShow">
									<th class="th" width="2%"></th>
									<th class="th" width="15%">
										<span v-if="allLinkmanList.length <= 1">联系人</span>
										<select v-if="allLinkmanList.length > 1" v-model="rCheckLinkmanId" @change="rLinkmanChange()">
											<option v-for="item in allLinkmanList" :value="item.id">{{item.email}}</option>
										</select>
									</th>
									<th class="th" width="53%">主题</th>
									<th class="th" width="10%">大小</th>
									<th class="th" width="10%">时间</th>
									<th class="th" width="10%">文件夹</th>
								</tr>
								<tr v-for="tab in rmailList" v-if="emailShow && rmailList.length > 0">
									<td width="2%" class="td spot-box">
										</span>
									</td>
									<td class="td cnt-name" width="15%">
										<span class="read-cnt_name" @click="lookMailName(tab.name_email)">
                					<el-tooltip class="item" effect="dark" :open-delay="300" content="点击查看" placement="top-start">
      										<span>{{tab.name}}</span>
										</el-tooltip>
										</span>
									</td>
									<td class="td" width="53%">
										<div class="max-width-3 limit-show">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.title" placement="top-start">
												<span>{{tab.title}}</span>
											</el-tooltip>
										</div>

									</td>
									<td class="td" width="10%">{{tab.size}}</td>
									<td class="td" width="10%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.date.split(' ')[1]" placement="top-start">
											<span>{{tab.date.split(' ')[0]}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="10%">{{tab.folder}}</td>
								</tr>
								<tr v-if="emailShow && rmailList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
								<!--发件箱-->
								<tr v-if="!emailShow">
									<th class="th" width="2%"></th>
									<th class="th" width="15%">
										<span v-if="allLinkmanList.length <= 1">联系人</span>
										<select v-if="allLinkmanList.length > 1" v-model="rCheckLinkmanId" @change="rLinkmanChange()">
											<option v-for="item in allLinkmanList" :value="item.id">{{item.email}}</option>
										</select>
									</th>
									<th class="th" width="53%">主题</th>
									<th class="th" width="10%">大小</th>
									<th class="th" width="10%">时间</th>
									<th class="th" width="10%">文件夹</th>
								</tr>
								<tr v-for="tab in smailList" v-if="!emailShow && smailList.length > 0">
									<td width="2%" class="td spot-box">
										</span>
									</td>
									<td class="td cnt-name" width="15%">
										<span class="read-cnt_name" @click="lookMailName(tab.name_email)">
                			<el-tooltip class="item" effect="dark" :open-delay="300" content="点击查看" placement="top-start">
      								<span>{{tab.name}}</span>
										</el-tooltip>
										</span>
									</td>
									<td class="td" width="53%">
										<div class="max-width-3 limit-show">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.title" placement="top-start">
												<span>{{tab.title}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="10%">{{tab.size}}</td>
									<td class="td" width="10%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.date.split(' ')[1]" placement="top-start">
											<span>{{tab.date.split(' ')[0]}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="10%">{{tab.folder}}</td>
								</tr>
								<tr v-if="!emailShow && smailList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
							</tbody>
						</table>
						<!--收件箱·分页-->
						<el-pagination v-if="emailShow && rmailList.length > 0" class="paging" :current-page.sync="rmail_currpage" :page-size="20" :total="rmail_tolRecord" layout="total,prev, pager, next" :page-count='rmail_tolpage' @current-change='getRMailList()'>
						</el-pagination>
						<!--发件箱·分页-->
						<el-pagination v-if="!emailShow && smailList.length > 0" class="paging" :current-page.sync="smail_currpage" :page-size="20" :total="smail_tolRecord" layout="total,prev, pager, next" :page-count='smail_tolpage' @current-change='getSMailList()'>
						</el-pagination>
					</el-tab-pane>
					<!--跟进-->
					<el-tab-pane label="跟进" name="fiveth">
						<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%">
							<tbody>
								<tr>
									<td colspan="4" class="connect-title">
										<div class="tab-button plan">
											<el-radio-group v-model="radio4">
												<span @click="planShow=true"><el-radio :label="1" >计划</el-radio></span>
												<span @click="planShow=false"><el-radio :label="2" >记录</el-radio></span>
											</el-radio-group>
										</div>
									</td>
								</tr>
								<!--计划-->
								<tr v-if="planShow">
									<th class="th" width="5%"></th>
									<th class="th" width="15%">
										<select v-model="wayId">
											<option :value="way.id" v-for="way in followupWayList" :title="way.name">{{way.name1}}</option>
										</select>
									</th>
									<th class="th" width="30%">跟进内容</th>
									<th class="th" width="12.5%">跟进时间</th>
									<th class="th" width="12.5%">创建人</th>
									<th class="th" width="20%">
										<select v-model="planType">
											<option value="-2">所有计划</option>
											<option v-for="plan in planTypes" :value="plan.typeid">{{plan.name}}</option>
										</select>
									</th>
									<th class="th" width="5%">查看</th>
								</tr>
								<tr v-for="(tab,index) in planList" v-if="planShow && planList.length > 0">
									<td class="td spot-box" width="5%"></td>
									<td class="td cnt-name" width="15%">{{tab.tool}}</td>
									<td class="td cnt-name" width="30%">
										<div class="max-width-4 limit-show">
											<el-tooltip class="item" effect="light" :open-delay="300" :content="tab.content" placement="top-start">
												<span>{{tab.content}}</span>
											</el-tooltip>
										</div>

									</td>
									<td class="td" width="12.5%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.date.split(' ')[1]" placement="top-start">
											<span>{{tab.date.split(' ')[0]}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="12.5%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.user_name" placement="top-start">
											<span>{{tab.user_name}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="20%">{{tab.plan}}</td>
									<td class="td cnt-name" width="5%">
										<span class="read-cnt_name" @click="lookFollowup(tab.id)">
      										<i class="icon iconfont icon-eyes read-cnt_name"></i>
                						</span>
									</td>
								</tr>
								<tr v-if="planShow && planList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
								<!--记录-->
								<tr v-if="!planShow">
									<th class="th" width="5%"></th>
									<th class="th" width="15%">
										<select v-model="wayId">
											<option :value="way.id" v-for="way in followupWayList" :title="way.name">{{way.name1}}</option>
										</select>
									</th>
									<th class="th" width="30%">跟进内容</th>
									<th class="th" width="12.5%">跟进时间</th>
									<th class="th" width="12.5%">创建人</th>
									<th class="th" width="20%">
										<select v-model="recordType">
											<option value="-2">所有记录</option>
											<option v-for="record in recordTypes" :value="record.typeid">{{record.name}}</option>
										</select>
									</th>
									<th class="th" width="5%">查看</th>
								</tr>
								<tr v-for="tab in recordList" v-if="!planShow && recordList.length > 0">
									<td class="td spot-box" width="5%"></td>
									<td class="td cnt-name" width="15%">{{tab.tool}}</td>
									<td class="td cnt-name" width="30%">
										<div class="max-width-4 limit-show">
											<el-tooltip class="item" effect="light" :open-delay="300" :content="tab.content" placement="top-start">
												<span>{{tab.content}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="12.5%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.date.split(' ')[1]" placement="top-start">
											<span>{{tab.date.split(' ')[0]}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="12.5%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.user_name" placement="top-start">
											<span>{{tab.user_name}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="20%">{{tab.record}}</td>
									<td class="td cnt-name" width="5%">
										<span class="read-cnt_name" @click="lookFollowup(tab.id)">
      										<i class="icon iconfont icon-eyes read-cnt_name"></i>
                						</span>
									</td>
								</tr>
								<tr>
								</tr>
								<tr v-if="!planShow && recordList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
							</tbody>
						</table>
						<!--计划·分页-->
						<el-pagination v-if="planShow && planList.length>0" class="paging" :current-page.sync="follow_plan_currpage" :page-size="5" :total="follow_plan_tolRecord" layout="total,prev, pager, next" :page-count='follow_plan_tolpage' @current-change='getFollowPlanList()'>
						</el-pagination>
						<!--记录·分页-->
						<el-pagination v-if="!planShow && recordList.length>0" class="paging" :current-page.sync="follow_record_currpage" :page-size="5" :total="follow_record_tolRecord" layout="total,prev, pager, next" :page-count='follow_record_tolpage' @current-change='getFollowRecordList()'>
						</el-pagination>
					</el-tab-pane>
					<!--商机-->
					<!--<el-tab-pane label="商机" name="seventh">
						<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%">
							<tbody>
								<tr>
									<th class="th" width="5%"></th>
									<th class="th" width="15%">机会来源</th>
									<th class="th" width="15%">预成交时间</th>
									<th class="th" width="40%">备注信息</th>
									<th class="th" width="20%">当前状态</th>
									<th class="th" width="5%">查看</th>
								</tr>
								<tr v-for="tab in chanceList" v-if="chanceList.length > 0">
									<td width="5%" class="td spot-box">
										</span>
									</td>
									<td class="td" width="15%">{{tab.source}}</td>
									<td class="td" width="15%">{{tab.date}}</td>
									<td class="td" width="40%">
										<div class="max-width-3 limit-show">
											<el-tooltip class="item" effect="light" :open-delay="300" :content="tab.note" placement="top-start">
												<span>{{tab.note}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="20%">{{tab.status}}</td>
									<td class="td cnt-name" width="5%">
										<span class="read-cnt_name" @click="lookChane(tab.id)">
      							<i class="icon iconfont icon-eyes read-cnt_name"></i>
                	</span>
									</td>
								</tr>
								<tr v-if="chanceList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
							</tbody>
						</table>-->
					<!--分页-->
					<!--<el-pagination v-if="chanceList.length > 0" class="paging" :current-page.sync="chance_currpage" :page-size="5" :total="chance_tolRecord" layout="total,prev, pager, next" :page-count='chance_tolpage' @current-change='getChanceList()'>
						</el-pagination>
					</el-tab-pane>-->
					<!--订单-->
					<el-tab-pane label="订单" name="eighth">
						<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%">
							<tbody>
								<tr>
									<th class="th" width="5%"></th>
									<th class="th" width="15%">订单类型</th>
									<th class="th" width="15%">成交时间</th>
									<th class="th" width="15%">成交金额</th>
									<th class="th" width="22%">备注信息</th>
									<th class="th" width="15%">创建人</th>
									<th class="th" width="8%">查看</th>
								</tr>
								<tr v-for="one in orderFirstLists" v-if="orderFirstLists.length > 0">
									<td class="td" width="5%"></td>
									<td class="td" width="15%" style="color: red;">{{one.type}}</td>
									<td class="td" width="15%" style="color: red;">--</td>
									<td class="td" width="15%" style="color: red;">{{one.money}}</td>
									<td class="td" width="25%" style="color: red;">--</td>
									<td class="td" width="15%" style="color: red;">--</td>
									<td class="td" width="5%" style="color: red;">--</td>
								</tr>
								<tr v-for="tab in saleorderList" v-if="saleorderList.length > 0">
									<td class="td" width="5%"></td>
									<td class="td" width="15%">{{tab.type}}</td>
									<td class="td" width="15%">{{tab.date}}</td>
									<td class="td" width="15%">{{tab.money}}</td>
									<td class="td" width="22%">
										<div class="max-width-3 limit-show">
											<el-tooltip class="item" effect="light" :open-delay="300" :content="tab.note" placement="top-start">
												<span>{{tab.note}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="15%">{{tab.user_name}}</td>
									<td class="td cnt-name" width="8%">
										<i title="查看订单详情" class="icon iconfont icon-eyes alibabaicon" @click="lookSaleRecord(tab.id)"></i>
										<!--<i title="查看关联产品" @click="showProductList(4,tab.id)" class="icon iconfont icon-2chanpinzhanshieps-copy alibabaicon"></i> &nbsp;-->
									</td>
								</tr>
								<tr v-if="saleorderList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
							</tbody>
						</table>
						<!--分页-->
						<el-pagination v-if="saleorderList.length > 0" class="paging" :current-page.sync="saleorder_currpage" :page-size="5" :total="saleorder_tolRecord" layout="total,prev, pager, next" :page-count='saleorder_tolpage' @current-change='getSaleRecordList()'>
						</el-pagination>
					</el-tab-pane>
					<!--文件-->
					<el-tab-pane label="文件" name="nineth" v-if="userparamlist.param213 != undefined && userparamlist.param213 != 0">
						<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%" v-loading="autographLoading" element-loading-text="拼命加载中">
							<tbody>
								<tr>
									<td colspan="100%">
										<div class="cus_dire">
											<span class="all_file">全部文件</span>
											<ul class="ul-bar">
												<li>
													<a href="javascript:;" @click="returnToUpperLevel" style="color: #20A0FF;">返回上一级</a>
													<span class="line-bar">|</span></li>
												<li>
													<a href="javascript:;" @click="loadAllFile" style="color: #20A0FF;">全部文件</a>
													<span class="line-bar">></span>
													<span id="titleTip" title="全部文件/我的资源">{{fileDirectory}}</span>
												</li>
											</ul>
										</div>
									</td>
								</tr>
								<tr>
									<th class="th" width="5%"></th>
									<th class="th" width="30%">文件名</th>
									<th class="th" width="18%">上传时间</th>
									<th class="th" width="20%">类别</th>
									<th class="th" width="10%">大小</th>
									<th class="th" width="15%">创建人</th>
									<!--<th class="th" width="25%">查看</th>-->
								</tr>
								<tr v-for="tab in fileuploadList" v-if="fileuploadList.length > 0">
									<td class="td" width="5%" style="padding-left: 10px;">
										<div class="file-ico" :class="tab.file_mate"></div>
									</td>
									<td class="td" width="30%">
										<!-- 文件 -->
										<div v-if="tab.type == 1" @click='lookFile(tab.token,tab.name)' class="maxfile-width">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.name" placement="top">
												<span>{{tab.name}}</span>
											</el-tooltip>
										</div>
										<!-- 文件夹 -->
										<div v-if="tab.type == 0" class="maxfile-width" @click='lookNextFile(tab.id,tab.name)'>
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.name" placement="top">
												<span>{{tab.name}}</span>
											</el-tooltip>
										</div>
									</td>
									<td class="td" width="18%">
										<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.time1" placement="top">
											<span>{{tab.time}}</span>
										</el-tooltip>
									</td>
									<td class="td" width="20%">{{tab.from}}</td>
									<td class="td" width="10%">{{tab.size}}</td>
									<td class="td" width="15%">
										<div class="maxpro-width">
											<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.creator" placement="top">
												<span>{{tab.creator}}</span>
											</el-tooltip>
										</div>
									</td>
									<!--<td class="td cnt-name" width="25%">
										<span class="read-cnt_name" @click="lookFile(tab.token,tab.name)">
      									<i class="icon iconfont icon-eyes read-cnt_name"></i>
                					</span>
									</td>-->
								</tr>
								<tr v-if="fileuploadList.length == 0">
									<td colspan="100%" class="noData-box">
										<div class="noData-bg">
										</div>
										<span class="noData">暂无数据</span>
									</td>
								</tr>
							</tbody>
						</table>
						<!--分页-->
						<el-pagination v-if="fileuploadList.length > 0" class="paging" :current-page.sync="file_currpage" :page-size="file_pageSize" :total="file_tolRecord" layout="total,prev, pager, next" :page-count='file_tolpage' @current-change='getFileList(file_currpage)'>
						</el-pagination>
					</el-tab-pane>
				</el-tabs>
				<!--底部确认取消按钮-->
				<!--<span slot="footer" class="dialog-footer">-->
				<!--<el-button type="primary" @click="close">确 定</el-button>-->
				<!--<el-button @click="close">取 消</el-button>-->
				<!--</span>-->
			</el-dialog>
			<!--联系人详情-->
			<connectInfo class="connectLayer" v-if="showC" @cntClose="showC = false" :connectTab="connectTab" :client-list="false" cnt-title="联系人详情" :has-button="false">
			</connectInfo>
			<!--跟进详细信息-->
			<layerFollowInfo @flInfoClose="flInfoClose" :f-info="fInfo" v-if="showFlInfo">
			</layerFollowInfo>
			<!--商机详细信息-->
			<crmCompeleteRecordInfo v-if="showCRI" :business_data="business_data" @cntClose="cntClose">
			</crmCompeleteRecordInfo>
			<!--订单详细信息-->
			<crmSaleRecordInfo v-if="showSRI" :records_data="records_data" @srbClose="srbClose">
			</crmSaleRecordInfo>
		</div>
		<!-- 多联系电话弹出框 -->
		<el-dialog title="电话" :visible.sync="acus_phoneInfo" size="tiny" class='acus-popup4 dialogTwo' :close-on-click-modal="false" :modal="false">
			<table cellspacing="0" class="acusOpen-table">
				<thead>
					<tr>
						<td>序号</td>
						<td>电话</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in phoneArr">
						<td>
							<span>{{index + 1}}</span>
						</td>
						<td>
							<span>{{item}}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</el-dialog>
		<!--邮件收|发件人弹出框-->
		<el-dialog title="收件人信息" :visible.sync="acusR_moreaddress" size="tiny" class='acus-popup4 dialogTwo' :close-on-click-modal="false" :modal="false">
			<table cellspacing="0" class="acusOpen-table">
				<thead>
					<tr>
						<td>收件人</td>
						<td>邮箱地址</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in addressList">
						<td>
							<span>{{item.receivername}}</span>
						</td>
						<td>
							<span>{{item.email}}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</el-dialog>
		<!-- 关联产品 -->
		<el-dialog title="关联产品" :visible.sync="cusProShow" size="tiny" class='cus-proinfo dialogTwo' :close-on-click-modal="false" :modal="false">
			<table class="pro-table" cellspacing="0">
				<tr v-for="item in orderInfo">
					<td class="re-td">名称</td>
					<td>
						<div class="maxpro-width" :title="item.pro_name">{{item.pro_name}}</div>
					</td>
					<td class="re-td">金额</td>
					<td>
						<div class="maxpro-width" :title="item.product_money">{{item.product_money}}&nbsp;万&nbsp;{{item.proM_unit}}</div>
					</td>
					<td class="re-td">数量</td>
					<td>
						<div class="maxpro-width" :title="item.product_num">{{item.product_num}}&nbsp;{{item.proNum_unit}}</div>
					</td>
				</tr>
			</table>
		</el-dialog>
		<!-- 订单详情 -->
		<el-dialog title="订单详情" :visible.sync="cusProDShow" size="tiny" class='cus-proinfo dialogTwo' :close-on-click-modal="false" :modal="false">
			<table class="pro-table" cellspacing="0">
				<tr>
					<td colspan="1" class="re-td">客户名称</td>
					<td colspan="1">
						<div class="maxdata-width data-name" :title="orderInfo1.customer_name">{{orderInfo1.customer_name}}</div>
					</td>
					<td colspan="1" class="re-td">订单类型</td>
					<td colspan="3">{{orderInfo1.record_type}}</td>
				</tr>
				<tr>
					<td colspan="1" class="re-td">成交总额</td>
					<td colspan="1">{{orderInfo1.transcation_ammount}}&nbsp;万&nbsp;{{orderInfo1.sumMoney_unit}}</td>
					<td colspan="1" class="re-td">成交时间</td>
					<td colspan="3">{{orderInfo1.contract_time}}</td>
				</tr>
				<tr>
					<td colspan="1" class="re-td">创建时间</td>
					<td colspan="1">{{orderInfo1.create_date}}</td>
					<td colspan="1" class="re-td">物流公司</td>
					<td colspan="3">{{orderInfo1.logistics_company_name}}</td>
				</tr>
				<tr>
					<td colspan="1" class="re-td">物流方式</td>
					<td colspan="1">{{orderInfo1.logistics_way_name}}</td>
					<td colspan="1" class="re-td">物流费用</td>
					<td colspan="3">{{orderInfo1.logistics_money}}&nbsp;{{orderInfo1.loMoney_unit}}</td>
				</tr>
				<tr>
					<td colspan="1" class="re-td">订单状态</td>
					<td colspan="5">{{orderInfo1.order_status}}</td>
				</tr>
				<tr v-for="item in orderInfo">
					<td class="re-td">名称</td>
					<td>
						<div class="maxpro-width" :title="item.pro_name">{{item.pro_name}}</div>
					</td>
					<td class="re-td">金额</td>
					<td>
						<div class="maxpro-width" :title="item.product_money">{{item.product_money}}&nbsp;万&nbsp;{{item.proM_unit}}</div>
					</td>
					<td class="re-td">数量</td>
					<td>
						<div class="maxpro-width" :title="item.product_num">{{item.product_num}}&nbsp;{{item.proNum_unit}}</div>
					</td>
				</tr>
				<tr>
					<td colspan="1" class="re-td">备注信息</td>
					<td colspan="5">{{orderInfo1.sales_desc}}</td>
				</tr>
				<tr v-show="orderInfo2.length > 0">
					<td colspan="6">自定义字段</td>
				</tr>
				<tr v-for="item in orderInfo2">
					<!--<td class="re-td">名称</td>-->
					<td colspan="1" class="re-td">{{item.field_name}}</td>
					<!--<td class="re-td">对应值</td>-->
					<td colspan="5">{{item.field_value}}</td>
				</tr>
			</table>
		</el-dialog>

		<!--预览-->
		<div>
			<transition name="fade">
				<div class="lookFj" v-show='lookFj'>
					<div class="lf-header w100">
						<div class="lfh-title" :title="looFjName" style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 300px;">{{looFjName}}</div>
						<div class="lfh-download " @click='downloadCommonMethod([fileToken,0])'>
							<i class="icon iconfont icon-download" style="font-size: 18px;"></i>
							<span>下载</span>
						</div>
						<div class="lfh-close " @click='lookFj = false'>
							<i class="icon iconfont icon-close color-white"></i>
							<span>关闭</span>
						</div>
					</div>

					<div class="lf-content w100">
						<iframe :src="lookFjUrl" width="90%" height="100%" class="lfc-main"></iframe>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<style  src="@/css/common.css"></style>
<script>
	import layerFollowInfo from "./layer_content_info.vue" //跟进
	import connectInfo from "./smallLayer.vue" //跟进详细信息弹窗
	import crmCompeleteRecordInfo from './layer_business_details.vue' //商机详细信息弹窗
	import crmSaleRecordInfo from './layer_compelete_record_info.vue' //订单详细信息弹窗
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	import lookProduct from "@/components/common_file/com_product/lookProduct";
	export default {
		data() {
			return {
				autographLoading: false,
				//customer_img:'../../../../static/img/common/user.png',//企业头像
				customer_img: backUrl + 'img/male_user.png', //企业头像
				link_name: '', //企业联系人姓名
				followup_name: '未知用户', //跟进人姓名
				dynamicType: 0, //动态类型,0动态,1跟进记录,2生命线
				//动态集合
				dynamicList: [],
				//跟进记录集合
				recordList: [],
				//生命线集合
				lifeList: [],
				classify_name: '未知',
				source_name: '未知',
				relation_name: '未知',
				status_name: '未知',
				hangye_name: '未知',
				product_name: '未知',
				productIds:'',//产品id
				productIdLoading:false,//产品loading
				//联系人集合
				linkmanList: [],
				linkman_currpage: 1, //联系人当前页
				linkman_tolpage: 1, //联系人总页数
				linkman_tolRecord: 1, //联系人总条数
				acus_phoneInfo: false, //多联系电话标识
				acusR_moreaddress: false, //邮件收|发件人显示标识
				addressList: [], //邮件收|发件人信息集合
				phoneArr: [], //联系电话集合
				connectTab: [], //联系人信息实体

				//收件箱集合
				rmailList: [],
				rmail_currpage: 1, //收件箱当前页
				rmail_tolpage: 1, //收件箱总页数
				rmail_tolRecord: 1, //收件箱总条数
				//发件箱集合
				smailList: [],
				smail_currpage: 1, //发件箱当前页
				smail_tolpage: 1, //发件箱总页数
				smail_tolRecord: 1, //发件箱总条数
				allLinkmanList: [],//所有联系人集合
				rCheckLinkmanId: 0,//收件箱选择的联系人id
				sCheckLinkmanId: 0,//发件箱选择的联系人id
				
				//跟进方式集合
				followupWayList: [],
				wayId: 0, //选择的跟进方式
				fInfo: {}, //跟进信息实体
				//跟进计划集合
				planList: [],
				follow_plan_currpage: 1, //跟进计划当前页
				follow_plan_tolpage: 1, //跟进计划总页数
				follow_plan_tolRecord: 1, //跟进计划总条数
				planTypes: [], //计划类型集合
				planType: -2, //选择的计划类型
				//跟进记录集合
				recordList: [],
				follow_record_currpage: 1, //跟进记录当前页
				follow_record_tolpage: 1, //跟进记录总页数
				follow_record_tolRecord: 1, //跟进记录总页数
				recordTypes: [], //记录类型集合
				recordType: -2, //选择的记录类型

				business_data: {}, //商机信息实体
				chanceList: [], //商机集合
				chance_currpage: 1, //商机当前页
				chance_tolpage: 1, //商机总页数
				chance_tolRecord: 1, //商机总条数
				records_data: {}, //订单信息实体
				saleorderList: [], //订单集合
				saleorder_currpage: 1, //订单当前页
				saleorder_tolpage: 1, //订单总页数
				saleorder_tolRecord: 1, //订单总条数
				activeName: 'first', //顶部标签切换

				tabLists: [{
					text: '动态'
				}, 
//				{
//					text: '跟进记录'
//				}, 
				{
					text: '生命线'
				}],

				currentPage: 1,
				dateTitle: '',
				radio3: 1,
				radio4: 1,
				test: true,
				showC: false, //是否显示联系人弹出框
				showFlInfo: false, //是否显示跟进弹出框
				showCRI: false, //是否显示商机弹出框
				showSRI: false, //是否显示订单弹出框
				emailShow: true,
				planShow: true,
				createClTab: [],

				//文件
				fileuploadList: [],
				file_currpage: 1, //订单当前页
				file_tolpage: 1, //订单总页数
				file_tolRecord: 1, //订单总条数
				file_pageSize: 1, //每页数量
				lookFj: false, //文件预览
				looFjName: '', //文件预览-文件名
				fileToken: '', //文件预览-token
				lookFjUrl: '', //文件预览-url

				cusProShow: false, //订单关联产品
				orderInfo: [], //订单关联产品信息
				cusProDShow: false, //订单详情
				orderInfo1: {},
				orderInfo2: [],
				orderFirstLists: [], //总计
				fileDirectory: "", //文件/文件夹名称
				fileid: 0, //文件id
				cusUploadData: [], //上传文件数据
				cusUploadRecord: [], //总条数

				//背景
				cusR_backgroundInfos: {}, //背景信息
				//采购数据
				cusR_joinAliTime: '', //加入阿里时间
				cusR_xunpanPro: '', //询盘产品
				cusR_purchaseInfos: {}, //采购数据信息集合
				cusR_imgList: [], //询盘产品集合
				cusR_purchaseNeedList: [], //采购需求集合

				//邮箱集合
				cusr_mailAccountList: [],
				cusr_selectMailAccount: 0, //选中的邮箱账号id
				cusr_mailAccount: "", //邮箱账号
				cusr_mailAccountId: 0, //邮箱账号id
				cusr_poporimap: 0, //判断邮箱属于pop还是imap
				//自定义信息
				cusFieldList:[],//自定义信息集合
				country_flag :'', // 国旗
				
				isAlibabaOpen: false,//判断阿里巴巴插件是否开通
			};
		},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				customerInformation: state => state.customerInformation,
				userlistall: state => state.userlistall,
				customerclassifyall: state => state.customerclassifyall,
				customerfromall: state => state.customerfromall,
				customerrelationall: state => state.customerrelationall,
				customerstatusall: state => state.customerstatusall,
				industryType: state => state.hangyeArr,
				majorproductall: state => state.majorproductall,
				followupwayall: state => state.followupwayall,
				saleprocessall: state => state.saleprocessall,
				tradecurrencyall: state => state.tradecurrencyall,
				planOrRecordStatus: state => state.planOrRecordStatus,
				userparamlist: state => state.userparamlist,
				calculateInfo: state => state.calculateInfo,
				orderStatusInfo: state => state.orderStatusInfo,
				relationProduct: state => state.relationProduct,
				folderid: state => state.fileUpdate.folderid,
				team_mailAccount: state => state.team_mailAccount,
				countryArr: state => state.countryArr,
			})
		},
		watch: {
			dynamicType(newv, oldv) {
				if(newv == 0) { //动态
					this.dynamicList = [];
					this.showDynamic();
				} 
//				else if(newv == 1) { //跟进记录
//					this.recordList = [];
//					this.showFollowupRecord();
//				} 
				else if(newv == 1) { //生命线
					this.lifeList = [];
					this.showLifeRecycle();
				}
			},
			activeName(newv, oldv) {
				if(newv == 'third') { //加载联系人列表
					this.getLinkManList()
				} else if(newv == 'fiveth') { //加载跟进列表
					if(this.followupwayall == '') {
						this.getFollowupWayAll();
					}
					var lef = this;
					lef.followupWayList = [];
					lef.followupWayList.push({
						"id": 0,
						"name": "联系工具",
						"name1": "联系工具"
					});
					$.each(lef.followupwayall, function(index, row) {
						var name1 = row.name;
						if(name1.length > 6) {
							name1 = name1.substring(0, 6) + "...";
						}
						lef.followupWayList.push({
							"id": row.id,
							"name": row.name,
							"name1": name1
						});
					});
					if(this.planShow) { //显示计划
						this.getFollowPlanList();
						$.each(lef.planOrRecordStatus, function(i, row) {
							if(row.typeid < 2) {
								lef.planTypes.push({
									typeid: row.typeid,
									name: row.name,
								})
							}
						});
					} else { //显示记录
						this.getFollowRecordList();
					}
				} else if(newv == 'seventh') { //加载商机列表
					this.getChanceList();
				} else if(newv == 'eighth') { //加载订单列表
					this.getSaleRecordList();
				} else if(newv == 'sixth') { //加载邮件列表

					this.loadMailAccountByID();
					//					if(this.emailShow) { //显示收件箱列表
					//						this.getRMailList();
					//					} else { //显示发件箱列表
					//						this.getSMailList();
					//					}
				} else if(newv == 'nineth') {
					this.getFileList(1)
				} else if(newv == 'ten' || newv == 'eleven') {
					this.getCusRBgInfo();
				}
			},
			planShow() {
				this.recordTypes = [];
				if(this.planShow) { //加载跟进计划列表
					this.getFollowPlanList();
				} else { //加载跟进记录列表
					this.getFollowRecordList();
					var lef = this;
					$.each(lef.planOrRecordStatus, function(i, row) {
						if(row.typeid >= 2) {
							lef.recordTypes.push({
								typeid: row.typeid,
								name: row.name,
							})
						}
					});
				}
			},
			wayId(newv, oldv) {
				if(this.planShow) { //加载跟进计划列表
					this.getFollowPlanList();
				} else { //加载跟进记录列表
					this.getFollowRecordList();
				}
			},
			planType() {
				this.getFollowPlanList();
			},
			recordType() {
				this.getFollowRecordList();
			},
			emailShow() {
				if(this.emailShow) { //加载收件箱
					this.getRMailList();
				} else { //加载发件箱
					this.getSMailList();
				}
			},
		},
		components: {
			connectInfo,
			layerFollowInfo,
			crmCompeleteRecordInfo,
			crmSaleRecordInfo,
			lookProduct
		},
		methods: {
			...mapMutations({
				getCustomerInformation: 'getCustomerInformation', //加载客户信息
				getUserlistAll: 'getUserlistAll', //加载企业下所有用户
				getCustomerClassifyAll: 'getCustomerClassifyAll',
				getCustomerFromAll: 'getCustomerFromAll',
				getCustomerRelationAll: 'getCustomerRelationAll',
				getCustomerStatusAll: 'getCustomerStatusAll',
				getMajorProductAll: 'getMajorProductAll',
				getFollowupWayAll: 'getFollowupWayAll',
				getSaleProcessAll: 'getSaleProcessAll',
				getTradeCurrencyAll: 'getTradeCurrencyAll',
				downloadCommonMethod: 'downloadCommonMethod',
				getCalculateAllInfo: 'getCalculateAllInfo',
				getOrderStatusAllInfo: 'getOrderStatusAllInfo',
				getRelationProduct: 'getRelationProduct',
				findCusFolderid: 'findCusFolderid',
				getMailAccountById: 'getMailAccountById'
			}),
			close: function() {
				this.$emit('closeInfo', false)
			},
			//跟进详细信息弹出框关闭
			flInfoClose() {
				this.showFlInfo = false;
			},
			//商机详细信息弹出框关闭
			cntClose() {
				this.showCRI = false;
			},
			//订单详细信息弹出框关闭
			srbClose() {
				this.showSRI = false;
			},
			//加载动态
			showDynamic() {
				var lef = this;
				$.ajax({
					"type": "POST",
					"async": false,
					"cache": false,
					"url": backUrl + "dynamic_getDynamicByCusid.action",
					data: {
						token: token,
						customer_id: lef.customer_id
					},
					success: function(msg) {
						$.each(msg, function(i, row) {
							var username = "未知用户";
							if(row.submit_user_id != lef.userinfo.user_id) {
								$.each(lef.userlistall, function(i, user) {
									if(row.submit_user_id == user.user_id) {
										username = user.user_name;
										return false;
									}
								});
							} else {
								username = "我";
							}
							lef.dynamicList.push({
								"username": username,
								"create_time": row.create_time,
								"dynamic_info": row.dynamic_info
							});
						});
					}
				});
			},
			//加载跟进记录
			showFollowupRecord() {
				var lef = this;
				$.ajax({
					"type": "POST",
					"async": false,
					"cache": false,
					"url": backUrl + "cusfu3_findAllFollowupRecord.action",
					data: {
						token: token,
						customer_id: lef.customer_id
					},
					success: function(msg) {
						$.each(msg.followuplist, function(i, row) {
							var username = "我";
							if(row.create_user_id != lef.userinfo.user_id) {
								$.each(lef.userlistall, function(i, user) {
									if(row.create_user_id == user.user_id) {
										username = user.user_name;
										return false;
									}
								});
							}
							lef.recordList.push({
								"username": username,
								"create_date": row.create_date,
								"followup_content": row.followup_content
							});
						});
					}
				});
			},
			//加载生命线
			showLifeRecycle() {
				var lef = this;
				$.ajax({
					"type": "POST",
					"async": false,
					"cache": false,
					"url": backUrl + "xcus_findLifeCycleById.action",
					data: {
						token: token,
						customer_id: lef.customer_id
					},
					success: function(msg) {
						$.each(msg.cycles, function(i, row) {
							var username = "未知用户";
							var owner_id = row.owner_id; // 用户id
							if(owner_id == lef.userinfo.user_id) {
								username = "我";
							} else {
								$.each(lef.userlistall, function(index, user) {
									if(owner_id == user.user_id) {
										username = user.user_name;
										return false;
									}
								});
							}
							var update_time = row.update_time.replace(".0", "");
							var operator_name = '';
							if(row.owner_type == 1) // 新建客户
							{
								operator_name = '新建了该客户'
							} else if(row.owner_type == 2) // 转移客户
							{
								operator_name = '转移该客户给我'
							} else if(row.owner_type == 3) // 领取客户
							{
								operator_name = '从公海库领取了该客户'
							}
							lef.lifeList.push({
								"username": username,
								"update_time": update_time,
								"operator_name": operator_name
							});
						});
					}
				});
			},
			//加载联系人列表
			getLinkManList() {
				var lef = this;
				lef.linkmanList = [];
				$.ajax({
					type: "POST",
					url: backUrl + "link_findLinkManByCustomeridDel.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						customerid: lef.customer_id,
						currpage: lef.linkman_currpage,
						pagesize: 5
					},
					success: function(msg) {
						lef.linkman_tolpage = msg.pageBean.totalPage; //总页数    
						lef.linkman_tolRecord = msg.pageBean.totalRecord; //总条数
						$.each(msg.crmlinkmanlist, function(index, row) {
							var tel_num = 0;
							if(row.phone != null && row.phone.trim() != '') {
								tel_num = row.phone.split("@G@").length;
							}
							lef.linkmanList.push({
								"linkman_id": row.linkman_id,
								"is_main": row.is_main_linkman,
								"name": row.full_name,
								"email": row.email,
								"date": row.enrol_time.split(" ")[0],
								"tel": row.phone,
								"tel_num": tel_num,
								"phone": row.mobile,
							})
						});
					}
				});
			},
			//查看多电话
			lookManyPhone(phone) {
				var lef = this;
				lef.phoneArr = [];
				$.each(phone.split('@G@'), function(index, row) {
					lef.phoneArr.push(row);
				});
				lef.acus_phoneInfo = true;
			},
			//查看联系人信息
			lookLinkMan(link_id) {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "link_findByLinkid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						linkmanid: link_id
					},
					success: function(msg) {
						lef.connectTab = [];
						lef.connectTab = [{
								"title": "姓名",
								"info": msg.full_name
							},
							{
								"title": "职位",
								"info": msg.post
							},
							{
								"title": "邮箱",
								"info": msg.email
							},
							{
								"title": "手机",
								"info": msg.mobile
							},
							{
								"title": "性别",
								"info": msg.sex
							},
							{
								"title": "地址",
								"info": msg.address
							},
							{
								"title": "网址",
								"info": msg.website
							}
						];
					}
				});
				this.showC = true;
			},

			//获取背景和询盘数据信息
			getCusRBgInfo() {
				var self = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cus_findCustomerInfo.action",
					dataType: "json",
					async: true,
					cache: false,
					beforeSend: function() {
						self.autographLoading = true;
					},
					complete: function() {
						self.autographLoading = false;
					},
					data: {
						token: token,
						customer_id: self.customer_id
					},
					success: function(msg) {
						//成立时间
						var build_time = "没有";
						if(msg.year_established != "" && msg.year_established != null && msg.year_established != undefined) {
							build_time = msg.year_established;
						}
						//商业类型
						var business_type = "没有";
						if(msg.business_type != "" && msg.business_type != null && msg.business_type != undefined) {
							business_type = msg.business_type;
						}
						//销售平台
						var sales_platform = "没有";
						if(msg.platforms_selling != "" && msg.platforms_selling != null && msg.platforms_selling != undefined) {
							sales_platform = msg.platforms_selling;
						}
						//主营产品
						var main_pro = "没有";
						if(msg.main_products != "" && msg.main_products != null && msg.main_products != undefined) {
							main_pro = msg.main_products;
						}
						//经营地址
						var business_addr = "没有";
						if(msg.operational_address != "" && msg.operational_address != null && msg.operational_address != undefined) {
							business_addr = msg.operational_address;
						}
						//公司简介(关于我们)
						var about_us = "没有";
						if(msg.about_us != "" && msg.about_us != null && msg.about_us != undefined) {
							about_us = msg.about_us;
						}
						self.cusR_backgroundInfos['build_time'] = build_time; //成立时间
						self.cusR_backgroundInfos['business_type'] = business_type; //商业类型
						self.cusR_backgroundInfos['sales_platform'] = sales_platform; //销售平台
						self.cusR_backgroundInfos['main_pro'] = main_pro; //主营产品
						self.cusR_backgroundInfos['business_addr'] = business_addr; //经营地址
						self.cusR_backgroundInfos['about_us'] = about_us; //公司简介(关于我们)

						//查询客户阿里询盘信息
						//加入阿里时间
						self.cusR_joinAliTime = "没有";
						if(msg.joined_alibaba_time != "" && msg.joined_alibaba_time != null && msg.joined_alibaba_time != undefined) {
							self.cusR_joinAliTime = msg.joined_alibaba_time;
						}
						//询盘产品
						self.cusR_xunpanPro = "没有";
						if(msg.inquiries_product != "" && msg.inquiries_product != null && msg.inquiries_product != undefined) {
							self.cusR_xunpanPro = msg.inquiries_product;
						}
						//年采购额
						var purchase_count = "没有";
						if(msg.annual_purchasing_volume != "" && msg.annual_purchasing_volume != null && msg.annual_purchasing_volume != undefined) {
							purchase_count = msg.annual_purchasing_volume;
						}
						//采购目的
						var purchase_purpose = "没有";
						if(msg.primary_sourcing_purpose != "" && msg.primary_sourcing_purpose != null && msg.primary_sourcing_purpose != undefined) {
							purchase_purpose = msg.annual_purchasing_volume;
						}
						//平均采购频率
						var purchase_frequency = "没有";
						if(msg.average_sourcing_frequency != "" && msg.average_sourcing_frequency != null && msg.average_sourcing_frequency != undefined) {
							purchase_frequency = msg.average_sourcing_frequency;
						}
						//偏好供应商类型
						var supplier_type = "没有";
						if(msg.preferred_supplier_qualifications != "" && msg.preferred_supplier_qualifications != null && msg.preferred_supplier_qualifications != undefined) {
							supplier_type = msg.preferred_supplier_qualifications;
						}
						//偏好行业
						var preferences_industry = "没有";
						if(msg.preferred_industries != "" && msg.preferred_industries != null && msg.preferred_industries != undefined) {
							preferences_industry = msg.preferred_industries;
						}
						//注册年限
						var register_year = "没有";
						if(msg.registered_years != "" && msg.registered_years != null && msg.registered_years != undefined) {
							register_year = msg.registered_years;
						}
						//登录天数
						var login_day = "没有";
						if(msg.days_signed_in != "" && msg.days_signed_in != null && msg.days_signed_in != undefined) {
							login_day = msg.days_signed_in;
						}
						//产品浏览数
						var pro_view = "没有";
						if(msg.product_views != "" && msg.product_views != null && msg.product_views != undefined) {
							pro_view = msg.product_views;
						}
						//搜索次数
						var search_count = "没有";
						if(msg.searches != "" && msg.searches != null && msg.searches != undefined) {
							search_count = msg.searches;
						}
						//有效询盘数/回复询盘数
						var valid_xunpan_count = "没有";
						if(msg.valid_inquiries_sentTo != "" && msg.valid_inquiries_sentTo != null && msg.valid_inquiries_sentTo != undefined) {
							valid_xunpan_count = msg.valid_inquiries_sentTo;
						}
						//被标为垃圾询盘数
						var rubbish_xunpan_count = "没有";
						if(msg.spam_inquiries != "" && msg.spam_inquiries != null && msg.spam_inquiries != undefined) {
							rubbish_xunpan_count = msg.spam_inquiries;
						}
						//有效RFQ数
						var RFQ_count = "没有";
						if(msg.valid_rfqs_submitted != "" && msg.valid_rfqs_submitted != null && msg.valid_rfqs_submitted != undefined) {
							RFQ_count = msg.valid_rfqs_submitted;
						}
						//收到报价数/查看报价数
						var offer_count = "没有";
						if(msg.quotations_receivedRead != "" && msg.quotations_receivedRead != null && msg.quotations_receivedRead != undefined) {
							offer_count = msg.quotations_receivedRead;
						}
						//被添加联系人数
						var add_concact_count = "没有";
						if(msg.listed_contact != "" && msg.listed_contact != null && msg.listed_contact != undefined) {
							add_concact_count = msg.listed_contact;
						}
						//被添加黑名单数
						var add_blacklist = "没有";
						if(msg.add_blacklist != "" && msg.add_blacklist != null && msg.add_blacklist != undefined) {
							add_blacklist = msg.add_blacklist;
						}
						//最近搜索词
						var recent_searches = "没有";
						if(msg.recent_searches != "" && msg.recent_searches != null && msg.recent_searches != undefined) {
							recent_searches = msg.recent_searches;
						}
						//最常采购行业
						var re_purchase_industry = "没有";
						if(msg.most_sourcing_industries != "" && msg.most_sourcing_industries != null && msg.most_sourcing_industries != undefined) {
							re_purchase_industry = msg.most_sourcing_industries;
						}
						//最新采购需求
						if(msg.latest_buying_requests != "" && msg.latest_buying_requests != null && msg.latest_buying_requests != undefined) {
							self.cusR_purchaseNeedList = msg.latest_buying_requests.split("###");
						}
						//询盘产品图片
						if(msg.latest_inquiries != "" && msg.latest_inquiries != null && msg.latest_inquiries != undefined) {
							self.cusR_imgList = msg.latest_inquiries.split("###");
						}
						self.cusR_purchaseInfos['purchase_count'] = purchase_count; //年采购额
						self.cusR_purchaseInfos['purchase_purpose'] = purchase_purpose; //采购目的
						self.cusR_purchaseInfos['purchase_frequency'] = purchase_frequency; //平均采购频率
						self.cusR_purchaseInfos['supplier_type'] = supplier_type; //偏好供应商类型
						self.cusR_purchaseInfos['preferences_industry'] = preferences_industry; //偏好行业
						self.cusR_purchaseInfos['register_year'] = register_year; //注册年限
						self.cusR_purchaseInfos['login_day'] = login_day; //登录天数
						self.cusR_purchaseInfos['pro_view'] = pro_view; //产品浏览数
						self.cusR_purchaseInfos['search_count'] = search_count; //搜索次数
						self.cusR_purchaseInfos['valid_xunpan_count'] = valid_xunpan_count; //有效询盘数/回复询盘数
						self.cusR_purchaseInfos['rubbish_xunpan_count'] = rubbish_xunpan_count; //被标为垃圾询盘数
						self.cusR_purchaseInfos['RFQ_count'] = RFQ_count; //有效RFQ数
						self.cusR_purchaseInfos['offer_count'] = offer_count; //收到报价数/查看报价数
						self.cusR_purchaseInfos['add_concact_count'] = add_concact_count; //被添加联系人数
						self.cusR_purchaseInfos['add_blacklist'] = add_blacklist; //被添加黑名单数
						self.cusR_purchaseInfos['recent_searches'] = recent_searches; //最近搜索词
						self.cusR_purchaseInfos['re_purchase_industry'] = re_purchase_industry; //最常采购行业
					}
				});
			},
			//加载所有联系人
			getAllLinkMan() {
				var lef = this;
				lef.allLinkmanList = [];
				$.ajax({
					type: "POST",
					url: backUrl + "link_findLinkManByCustomeridDel2.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						customerid: lef.customer_id,
					},
					success: function(msg) {
						if(msg.length > 1){
							lef.allLinkmanList.push({"id":0,"name":"所有人","email":"所有人"});
						}
						for(var i = 0;i < msg.length;i++){
							lef.allLinkmanList.push(
								{"id":msg[i].linkman_id,
								"name":msg[i].full_name,
								"email":msg[i].email}
							);
						}
					}
				});
			},
			//收件箱联系人改变事件
			rLinkmanChange(){
				this.rmail_currpage = 1;
				this.getRMailList();
			},
			//发件箱联系人改变事件
			sLinkmanChange(){
				this.smail_currpage = 1;
				this.getSMailList();
			},
			//加载收件箱列表
			getRMailList() {
				var lef = this;
				lef.rmailList = [];
				var action = 'contacts_findCusRMail.action';
				if(this.cusr_poporimap == 1) {
					action = 'contactsimap_findCusRMail.action';
				}
				$.ajax({
					type: "POST",
					url: backUrl + action,
					async: true,
					cache: false,
					dataType: "json",
					beforeSend: function() {
						lef.autographLoading = true;
					},
					complete: function() {
						lef.autographLoading = false;
					},
					data: {
						token: token,
						currentpage: lef.rmail_currpage,
						cusid: lef.customer_id,
						accountid: lef.cusr_mailAccountId,
						mailaccount: lef.cusr_mailAccount,
						linkid: lef.rCheckLinkmanId
					},
					success: function(msg) {
						lef.rmail_tolpage = msg.pageBean.totalPage; //总页数   
						lef.rmail_tolRecord = msg.pageBean.totalRecord; //总条数
						var rlists = "";
						if(lef.cusr_poporimap == 0) {
							rlists = msg.rmaillist;
						} else {
							rlists = msg.datalist;
						}
						if(rlists != "" && rlists != null) {
							$.each(rlists, function(index, row) {
								var from_name = "", //发件人
									from_mail = ""; //发件人邮箱
								var send_time = "";
								var folder = "";
								if(lef.cusr_poporimap == 0) {
									if(row.from_name != "" && row.from_name != null) {
										from_name = row.from_name;
									} else {
										from_name = row.from_email.split('@')[0];
									}
									from_mail = row.from_email;
									send_time = row.receibe_time;
									if(row.delete_flag == 1){//已删除(可见)
										folder = "已删除";
									}else if(row.delete_flag == 2){//已删除(不可见)
										folder = "已删除";
									}else{//收件箱
										if(row.status == 1){
											folder = "垃圾箱";
										}else{//收件箱
											folder = "收件箱";
										}
									}
								} else {
									var fromer = row.fromer;
									if(fromer != "" && fromer != null) {
										if(fromer.indexOf('<') != -1) {
											from_name = row.fromer.split('<')[0];
											from_mail = row.fromer.split('<')[1].split('>')[0];
										} else {
											from_name = row.fromer.split('@')[0];
											from_mail = row.fromer;
										}
									}
									send_time = row.send_time;
									if(row.delete_flag == 1){//已删除(可见)
										folder = "已删除";
									}else if(row.delete_flag == 2){//已删除(不可见)
										folder = "已删除";
									}else{//收件箱
										if(row.imap_folder_id == -4){
											folder = "垃圾箱";
										}else{//收件箱
											folder = "收件箱";
										}
									}
								}
								if(from_name.length > 12) {
									from_name = from_name.substring(0, 12) + "...";
								}
								var formatSubject = '无主题';
								if(row.subject.length > 0) {
									formatSubject = row.subject.substring(0, 30);
								}
								var linkTitle = formatSubject;
								linkTitle = $.trim(linkTitle);
								linkTitle = linkTitle.replace(/\n/g, "");
								linkTitle = linkTitle.replace(/\r/g, "");
								linkTitle = linkTitle.replace(/\"/g, "&quot;");
								linkTitle = linkTitle.replace(/\'/g, "&acute;");
								linkTitle = linkTitle.replace(/\：/g, ":");
								linkTitle = linkTitle.replace(/</g, "&lt;");
								linkTitle = linkTitle.replace(/>/g, "&gt;");
								linkTitle = linkTitle.replace(/ /g, "");
								linkTitle = linkTitle.replace(/ /g, "");
								lef.rmailList.push({
									"name": from_name,
									"name_email": from_name + "###" + from_mail,
									"title": linkTitle,
									"size": lef.handlerEmailSize(row.mail_size),
									"date": send_time.replace('.0', ''),
									"folder": folder
								})
							});
						}
					}
				});
			},
			//加载发件箱列表
			getSMailList() {
				var lef = this;
				lef.smailList = [];
				var action = 'contacts_findCusSMail.action';
				if(this.cusr_poporimap == 1) {
					action = 'contactsimap_findCusSMail.action';
				}
				$.ajax({
					type: "POST",
					url: backUrl + action,
					async: true,
					cache: false,
					dataType: "json",
					beforeSend: function() {
						lef.autographLoading = true;
					},
					complete: function() {
						lef.autographLoading = false;
					},
					data: {
						token: token,
						currentpage: lef.smail_currpage,
						cusid: lef.customer_id,
						accountid: lef.cusr_mailAccountId,
						mailaccount: lef.cusr_mailAccount,
						linkid: lef.sCheckLinkmanId
					},
					success: function(msg) {
						lef.smail_tolpage = msg.pageBean.totalPage; //总页数   
						lef.smail_tolRecord = msg.pageBean.totalRecord; //总条数
						var slists = "";
						if(lef.cusr_poporimap == 0) {
							slists = msg.smaillist;
						} else {
							slists = msg.datalist;
						}
						$.each(slists, function(index, row) {
							var name = '多个';
							var name_email = '';
							var send_time = "";
							var folder = "";
							if(lef.cusr_poporimap == 0) {
								if(row.detaillist.length == 1) {
									name = row.detaillist[0].email.split('@')[0];
									if(row.detaillist[0].receiver != null && row.detaillist[0].receiver != '') {
										name = row.detaillist[0].receiver;
									}
									name_email = name + '###' + row.detaillist[0].email;
								} else {
									for(var i = 0; i < row.detaillist.length; i++) {
										var sname = row.detaillist[i].email.split('@')[0];
										if(row.detaillist[i].receiver != null && row.detaillist[i].receiver != '') {
											sname = row.detaillist[i].receiver;
										}
										name_email += sname + '###' + row.detaillist[i].email + '@G@';
									}
									name_email = name_email.substring(0, name_email.length - 3);
								}
								send_time = row.create_time;
							} else {
								var receiver = row.receiver;
								var re_mail = "";
								if(receiver != "" && receiver != null){
									if(receiver.indexOf(',') != -1){ //多个收件人
										var str = receiver.split(',');
										for(var i = 0; i < str.length; i++){
											if(str[i].indexOf('<') != -1){
												name_email += str[i].split('<')[0] + '###' + str[i].split('<')[1].split('>')[0] + '@G@';
											}else{
												name_email += str[i].split('@')[0] + '###' + str[i] + '@G@';
											}
										}
										name_email = name_email.substring(0, name_email.length - 3);
									}else{
										if(receiver.indexOf('<') != -1){
											name = receiver.split('<')[0];
											re_mail = receiver.split('<')[1].split('>')[0];
										}else{
											name = receiver.split('@')[0];
											re_mail = receiver;
										}
										name_email = name + '###' + re_mail;
									}
								}
								send_time = row.send_time;
							}
							if(row.delete_flag == 1){//已删除(可见)
								folder = "已删除";
							}else if(row.delete_flag == 2){//已删除(不可见)
								folder = "已删除";
							}else{//收件箱
								folder = "已发邮件";
							}
							if(name.length > 12) {
								name = name.substring(0, 12) + "...";
							}
							var formatSubject = '无主题';
							if(row.subject.length > 0) {
								formatSubject = row.subject.substring(0, 30);
							}
							var linkTitle = formatSubject;
							linkTitle = $.trim(linkTitle);
							linkTitle = linkTitle.replace(/\n/g, "");
							linkTitle = linkTitle.replace(/\r/g, "");
							linkTitle = linkTitle.replace(/\"/g, "&quot;");
							linkTitle = linkTitle.replace(/\'/g, "&acute;");
							linkTitle = linkTitle.replace(/\：/g, ":");
							linkTitle = linkTitle.replace(/</g, "&lt;");
							linkTitle = linkTitle.replace(/>/g, "&gt;");
							linkTitle = linkTitle.replace(/ /g, "");
							linkTitle = linkTitle.replace(/ /g, "");
							lef.smailList.push({
								"name": name,
								"name_email": name_email,
								"title": linkTitle,
								"size": lef.handlerEmailSize(row.mail_size),
								"date": send_time.replace('.0', ''),
								"folder": folder
							})
						});
					}
				});
			},
			//查看邮件收|发件人
			lookMailName(names) {
				this.addressList = [];
				var name_arr = names.split("@G@");
				for(var i = 0; i < name_arr.length; i++) {
					this.addressList.push({
						"receivername": name_arr[i].split('###')[0],
						"email": name_arr[i].split('###')[1]
					});
				}
				this.acusR_moreaddress = true;
			},
			//加载跟进计划列表
			getFollowPlanList() {
				var lef = this;
				var followup_value = "plan###" + lef.wayId + "###" + lef.planType;
				lef.planList = [];
				$.ajax({
					type: "POST",
					url: backUrl + "cusfu3_findFollowupByCRM.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						value: followup_value,
						pagesize: 5,
						currpage: lef.follow_plan_currpage,
						customer_id: lef.customer_id
					},
					success: function(msg) {
						lef.follow_plan_tolpage = msg.pageBean.totalPage; //总页数   
						lef.follow_plan_tolRecord = msg.pageBean.totalRecord; //总条数
						$.each(msg.followuplist, function(index, row) {
							var tool = '未知';
							$.each(lef.followupwayall, function(i, way) {
								if(way.id == row.followup_way) {
									tool = way.name;
									return false;
								}
							});
							//跟进状态
							var plan = '';
							$.each(lef.planOrRecordStatus, function(m, n) {
								if(row.followup_classify == n.typeid) {
									plan = n.name;
									return false;
								}
							});
							//获取跟进内容的纯文本
							var fc = row.followup_content;
							fc = fc.replace(/<[^>]*>|/g, "");
							
							var user_name = "未知用户";
							$.each(lef.userlistall, function(i, user) {
								if(row.create_user_id == user.user_id) {
									user_name = user.user_name;
									return false;
								}
							});
							
							
							lef.planList.push({
								"id": row.id,
								"tool": tool,
								"content": fc,
								"date": row.followup_time,
								"plan": plan,
								"user_name": user_name,
							})
						});
					}
				});
			},
			//加载跟进记录列表
			getFollowRecordList() {
				var lef = this;
				var followup_value = "record###" + lef.wayId + "###" + lef.recordType;
				lef.recordList = [];
				$.ajax({
					type: "POST",
					url: backUrl + "cusfu3_findFollowupByCRM.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						value: followup_value,
						pagesize: 5,
						currpage: lef.follow_record_currpage,
						customer_id: lef.customer_id
					},
					success: function(msg) {
						lef.follow_record_tolpage = msg.pageBean.totalPage; //总页数   
						lef.follow_record_tolRecord = msg.pageBean.totalRecord; //总条数
						$.each(msg.followuplist, function(index, row) {
							var tool = '未知';
							if(row.followup_way == 5){
								tool = '营销';
							}
							$.each(lef.followupwayall, function(i, way) {
								if(way.id == row.followup_way) {
									tool = way.name;
									return false;
								}
							});
							//跟进状态
							var record = '';
							$.each(lef.planOrRecordStatus, function(m, n) {
								if(row.followup_classify == n.typeid) {
									record = n.name;
									return false;
								}
							});

							//获取跟进内容的纯文本
							var fc = row.followup_content;
							fc = fc.replace(/<[^>]*>|/g, "");
							
							var user_name = "未知用户";
							$.each(lef.userlistall, function(i, user) {
								if(row.create_user_id == user.user_id) {
									user_name = user.user_name;
									return false;
								}
							});
							
							lef.recordList.push({
								"id": row.id,
								"tool": tool,
								"content": fc,
								"date": row.followup_time,
								"record": record,
								"user_name": user_name,
							})
						});
					}
				});
			},
			//查看跟进信息
			lookFollowup(id) {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cusfu3_getFollowupById.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						followup_id: id
					},
					success: function(msg) {
						var mode = "未知";
						$.each(lef.followupwayall, function(index, row) {
							if(row.id == msg.followup_way) {
								mode = row.name;
								return false;
							}
						});
						var course = "未知";
						$.each(lef.saleprocessall, function(index, row) {
							if(row.id == msg.purpose_process) {
								course = row.name;
								return false;
							}
						});
						//获取跟进内容的纯文本
						var fc = msg.followup_content;
						fc = fc.replace(/<[^>]*>|/g, "");
						lef.fInfo = {
								"mode": mode,
								"Date": msg.followup_time,
								"remindDate": msg.remind_time,
								"course": course,
								"content": fc
							},
							lef.showFlInfo = true;
					}
				});
			},
			//加载商机列表
			getChanceList() {
				var lef = this;
				lef.chanceList = [];
				$.ajax({
					type: "POST",
					url: backUrl + "cusSC_findSaleschanceByCusid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						pagesize: 5,
						currpage: lef.chance_currpage,
						customerid: lef.customer_id
					},
					success: function(msg) {
						lef.chance_tolpage = msg.pageBean.totalPage; //总页数  chance_tolRecord
						lef.chance_tolRecord = msg.pageBean.totalRecord; //总条数
						$.each(msg.saleschancelist, function(index, row) {
							var source = '未知';
							$.each(lef.customerfromall, function(i, sfrom) {
								if(sfrom.id == row.source_from_id) {
									source = sfrom.name;
									return false;
								}
							});
							lef.chanceList.push({
								"id": row.id,
								"source": source,
								"date": row.pre_execute_time,
								"note": row.note_pad,
								"status": row.chance_status
							})
						});
					}
				});
			},
			//查看商机信息
			lookChane(id) {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cusSC_findSalesChanceByid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						salechanceid: id
					},
					success: function(msg) {
						var chance_origin = "未知";
						$.each(lef.customerfromall, function(index, row) {
							if(row.id == msg.source_from_id) {
								chance_origin = row.name;
								return false;
							}
						});
						var r_currency = "未知";
						if(lef.tradecurrencyall == '') {
							lef.getTradeCurrencyAll();
						}
						$.each(lef.tradecurrencyall.crmcurrencylist, function(index, row) {
							if(row.id == msg.money_type) {
								r_currency = row.name;
								return false;
							}
						});
						lef.business_data = {
							"chance_origin": chance_origin,
							"success_odds": msg.chance_level,
							"r_currency": r_currency,
							"compelete_sum": msg.pre_execute_amount,
							"compelete_time": msg.pre_execute_time,
							"now_status": msg.chance_status,
							"remark": msg.note_pad
						};
						lef.showCRI = true;
					}
				});
			},
			//加载订单列表
			getSaleRecordList() {
				var lef = this;
				lef.saleorderList = [];
				lef.orderFirstLists = [];
				$.ajax({
					type: "POST",
					url: backUrl + "cusPR_findProductSalesByCusID.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						pagesize: 5,
						currpage: lef.saleorder_currpage,
						customerid: lef.customer_id
					},
					success: function(msg) {
						lef.saleorder_tolpage = msg.pageBean.totalPage; //总页数  
						lef.saleorder_tolRecord = msg.pageBean.totalRecord; //总条数
						$.each(msg.crmproductsaleslist, function(index, row) {
							var user_name = "未知用户";
							$.each(lef.userlistall, function(i, user) {
								if(row.create_user_id == user.user_id) {
									user_name = user.user_name;
									return false;
								}
							});
							
							lef.saleorderList.push({
								"id": row.id,
								"type": row.record_type,
								"date": row.contract_time,
								"money": row.transcation_ammount,
								"note": row.sales_desc,
								"user_name": user_name,
							})
						});
						var first = lef.saleorderList.splice(0, 1);
						$.each(first, function(index, row) {
							var money = Math.round(row.money * 100) / 100;
							lef.orderFirstLists.push({
								money: money,
								type: row.type,
							})
						});
					}
				});
			},
			//查看关联产品
			showProductList(type, id) {
				var lef = this;
				lef.productList = [];
				lef.orderInfo = [];
				var param = [type, id];
				this.getRelationProduct(param);
				if(this.relationProduct != '' || this.relationProduct.length != 0) {
					//加载货币信息
					if(lef.tradecurrencyall == '') {
						lef.getTradeCurrencyAll();
					}
					//获取所有计量信息
					if(lef.calculateInfo == '') {
						lef.getCalculateAllInfo();
					}
					$.each(lef.relationProduct, function(i, row) {
						var proM_unit = "";
						var proNum_unit = "";
						$.each(lef.tradecurrencyall.crmcurrencylist, function(index, d1) {
							if(row.product_money_type == d1.id) {
								proM_unit = d1.name;
								return;
							}
						})
						$.each(lef.calculateInfo, function(index2, d2) {
							if(row.product_num_type == d2.id) {
								proNum_unit = d2.name;
								return;
							}
						})
						lef.orderInfo.push({
							pro_name: row.product_name,
							product_money: row.product_money,
							proM_unit: proM_unit,
							product_num: row.product_num,
							proNum_unit: proNum_unit,
						})
					});
					//lef.cusProShow = true;
				}
			},
			//查看订单信息
			lookSaleRecord(id) {
				var lef = this;
				lef.orderInfo1 = {};
				lef.orderInfo2 = [];
				$.ajax({
					type: "POST",
					url: backUrl + "cusPR_findSalesByid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						saleid: id
					},
					success: function(msg) {
						var r_currency = "未知";
						if(this.orderStatusInfo == "") {
							this.getOrderStatusAllInfo();
						}
						if(lef.tradecurrencyall == '') {
							lef.getTradeCurrencyAll();
						}
						var order_status_name = "";
						var sumMoney_unit = "";
						var loMoney_unit = "";
						var r_currency = "未知";
						lef.orderInfo1['customer_name'] = msg.customer_name;
						lef.orderInfo1['transcation_ammount'] = msg.transcation_ammount;
						lef.orderInfo1['logistics_company_name'] = msg.logistics_company_name;
						lef.orderInfo1['logistics_money'] = msg.logistics_money;
						lef.orderInfo1['create_date'] = msg.create_date;
						lef.orderInfo1['contract_time'] = msg.contract_time;
						lef.orderInfo1['logistics_money'] = msg.logistics_money;
						lef.orderInfo1['logistics_way_name'] = msg.logistics_way_name;
						lef.orderInfo1['record_type'] = msg.record_type;
						if(msg.order_status != null && msg.order_status != "") {
							var order_str = msg.order_status.split(","); //订单状态
							$.each(lef.orderStatusInfo, function(index, row1) {
								$.each(order_str, function(index2, row2) {
									if(row2 == row1.id) {
										order_status_name += row1.name + " , ";
										return;
									}
								})
							})
						}

						order_status_name = order_status_name.substring(0, order_status_name.length - 2);
						lef.orderInfo1['order_status'] = order_status_name;
						lef.orderInfo1['sales_desc'] = msg.sales_desc;
						$.each(lef.tradecurrencyall.crmcurrencylist, function(index3, row3) {
							if(msg.money_type == row3.id) { //总金额单位
								sumMoney_unit = row3.name;
								return;
							}
							if(msg.logistics_money_type == row3.id) { //物流费用单位
								loMoney_unit = row3.name;
								return;
							}
						})
						lef.orderInfo1['sumMoney_unit'] = sumMoney_unit;
						lef.orderInfo1['loMoney_unit'] = loMoney_unit;
						//遍历相关产品
						if(msg.customer_field != null && msg.customer_field != "" && msg.customer_field != undefined) {
							var field_str = msg.customer_field.split("@@G@@"); //自定义字段
							$.each(field_str, function(index, row) {
								lef.orderInfo2.push({
									field_name: row.split("###")[0],
									field_value: row.split("###")[1],
								})
							})
						}

						$.each(lef.tradecurrencyall.crmcurrencylist, function(index, row) {
							if(row.id == msg.money_type) {
								r_currency = row.name;
								return false;
							}
						});
						lef.records_data = {
							"indent_type": msg.record_type,
							"compelete_date": msg.contract_time,
							"r_currency": r_currency,
							"compelete_sum": msg.transcation_ammount,
							"buy_num": msg.buy_number,
							"remark": msg.sales_desc
						};
						//lef.showSRI = true;
						lef.cusProDShow = true;
					}
				});
				this.showProductList(4, id);
			},
			//处理邮件大小
			handlerEmailSize(emailSize) {
				var finalSize = "";
				var mailsizeM = emailSize / 1024 / 1024;
				var mailsiezeK = emailSize / 1024;
				if(mailsizeM > 1) {
					finalSize = mailsizeM.toFixed(2) + "M";
				} else {
					finalSize = mailsiezeK.toFixed(2) + "K";
				}
				return finalSize;
			},
			//获取文件列表
			getFileList(fileCurrentPage) {
				let self = this;
				$.ajax({
					type: "get",
					url: diskUrl + "/file/list",
					async: false,
					cache: false,
					data: {
						LoginUserToken: token,
						companyId: self.userinfo.company_id,
						folderId: self.fileid,
						currentPage: fileCurrentPage,
						pageSize: 5,
						customerId: self.customer_id,
					},
					beforeSend: function() {
						self.autographLoading = true;
					},
					complete: function() {
						self.autographLoading = false;
					},
					success: function(msg) {
						if(msg.code == 200) {
							self.fileuploadList = []
							self.file_currpage = msg.data.page.currentPage;
							self.file_tolpage = msg.data.page.totalPage;
							self.file_tolRecord = msg.data.page.totalRecord;
							self.file_pageSize = msg.data.page.pageSize;
							$.each(msg.data.list, (i, v) => {
								//文件大小
								let filesizeM = v.size / 1024 / 1024,
									failsize, filesizeK = v.size / 1024;
								if(filesizeM > 1) {
									failsize = filesizeM.toFixed(2) + 'M';
								} else {
									failsize = filesizeK.toFixed(2) + 'K';
								}
								var file_mate = "";
								if(v.type != 0) { //0:表示文件夹，1：表示文件
									var index1 = v.name.lastIndexOf(".");
									var index2 = v.name.length;
									var suffix = v.name.substring(index1 + 1, index2); //后缀名
									var fileType1 = suffix.toLowerCase();
									switch(fileType1) {
										case "txt":
											file_mate = "fl-txt";
											break;
										case "doc":
											file_mate = "fl-doc";
											break;
										case "docx":
											file_mate = "fl-doc";
											break;
										case "dotx":
											file_mate = "fl-doc";
											break;
										case "dotm":
											file_mate = "fl-doc";
											break;
										case "docm":
											file_mate = "fl-doc";
											break;
										case "dot":
											file_mate = "fl-doc";
											break;
										case "wps":
											file_mate = "fl-doc";
											break;
										case "wpt":
											file_mate = "fl-doc";
											break;
										case "vsd":
											file_mate = "fl-doc";
											break;
										case "rtf":
											file_mate = "fl-doc";
											break;
										case "jnt":
											file_mate = "fl-doc";
											break;
										case "pdf":
											file_mate = "fl-pdf";
											break;
										case "ppt":
											file_mate = "fl-ppt";
											break;
										case "pps":
											file_mate = "fl-ppt";
											break;
										case "pptx":
											file_mate = "fl-ppt";
											break;
										case "ppsx":
											file_mate = "fl-ppt";
											break;
										case "ppsm":
											file_mate = "fl-ppt";
											break;
										case "pptm":
											file_mate = "fl-ppt";
											break;
										case "potx":
											file_mate = "fl-ppt";
											break;
										case "potm":
											file_mate = "fl-ppt";
											break;
										case "ppam":
											file_mate = "fl-ppt";
											break;
										case "xls":
											file_mate = "fl-xls";
											break;
										case "xlt":
											file_mate = "fl-xls";
											break;
										case "xlsx":
											file_mate = "fl-xls";
											break;
										case "xlsm":
											file_mate = "fl-xls";
											break;
										case "xltx":
											file_mate = "fl-xls";
											break;
										case "xltm":
											file_mate = "fl-xls";
											break;
										case "xlsb":
											file_mate = "fl-xls";
											break;
										case "xlam":
											file_mate = "fl-xls";
											break;
										case "csv":
											file_mate = "fl-xls";
											break;
										case "jpg":
											file_mate = "fl-img";
											break;
										case "gif":
											file_mate = "fl-img";
											break;
										case "png":
											file_mate = "fl-img";
											break;
										case "jpeg":
											file_mate = "fl-img";
											break;
										case "bmp":
											file_mate = "fl-img";
											break;
										case "tif":
											file_mate = "fl-img";
											break;
										case "tiff":
											file_mate = "fl-img";
											break;
										case "ico":
											file_mate = "fl-img";
											break;
										case "fcx":
											file_mate = "fl-img";
											break;
										case "tga":
											file_mate = "fl-img";
											break;
										case "exif":
											file_mate = "fl-img";
											break;
										case "fpx":
											file_mate = "fl-img";
											break;
										case "svg":
											file_mate = "fl-img";
											break;
										case "eps":
											file_mate = "fl-img";
											break;
										case "psd":
											file_mate = "fl-ps";
											break;
										case "md":
											file_mate = "fl-md";
											break;
										case "json":
											file_mate = "fl-md";
											break;
										case "js":
											file_mate = "fl-md";
											break;
										case "less":
											file_mate = "fl-md";
											break;
										case "bat":
											file_mate = "fl-md";
											break;
										case "css":
											file_mate = "fl-md";
											break;
										case "java":
											file_mate = "fl-md";
											break;
										case "jsp":
											file_mate = "fl-md";
											break;
										case "class":
											file_mate = "fl-md";
											break;
										case "vue":
											file_mate = "fl-v";
											break;
										case "exe":
											file_mate = "fl-html";
											break;
										case "htm":
											file_mate = "fl-html";
											break;
										case "html":
											file_mate = "fl-html";
											break;
										case "mmap":
											file_mate = "fl-mmap";
											break;
										case "dmg":
											file_mate = "fl-dmg";
											break;
										case "ai":
											file_mate = "fl-ai";
											break;
										case "zip":
											file_mate = "fl-zip";
											break;
										case "rar":
											file_mate = "fl-zip";
											break;
										case "7z":
											file_mate = "fl-zip";
											break;
										case "mp4":
											file_mate = "fl-video";
											break;
										case "wmv":
											file_mate = "fl-video";
											break;
										case "avi":
											file_mate = "fl-video";
											break;
										case "flv":
											file_mate = "fl-video";
											break;
										case "rmvb":
											file_mate = "fl-video";
											break;
										case "rm":
											file_mate = "fl-video";
											break;
										case "flash":
											file_mate = "fl-video";
											break;
										case "3gp":
											file_mate = "fl-video";
											break;
										case "swf":
											file_mate = "fl-video";
											break;
										case "mp3":
											file_mate = "fl-audio";
											break;
										case "amr":
											file_mate = "fl-audio";
											break;
										case "wma":
											file_mate = "fl-audio";
											break;
										case "wav":
											file_mate = "fl-audio";
											break;
										case "aac":
											file_mate = "fl-audio";
											break;
										case "flac":
											file_mate = "fl-audio";
											break;
										case "ape":
											file_mate = "fl-audio";
											break;
										case "mid":
											file_mate = "fl-audio";
											break;
										case "ogg":
											file_mate = "fl-audio";
											break;
										case "torrent":
											file_mate = "fl-bt";
											break;
										default:
											file_mate = "fl-other";
									}
								} else {
									file_mate = "fl-folder";
								}
								let fromType = '';

								if(v.from == 2) {
									fromType = '订单'
								} else if(v.from == 3) {
									fromType = '邮件'
								} else if(v.from == 4) {
									fromType = '上传'
								} else if(v.from == 5) {
									fromType = '其他'
								}
								var creator = "未知";
								$.each(self.userlistall,function(i,row){
									if(v.userId == row.user_id){
										creator = row.user_name;
									}
								})
								self.fileuploadList.push({
									name: v.name,
									file_mate: file_mate,
									time: v.createTime.substring(0, 10),
									time1: v.createTime,
									creator:creator,
									size: failsize,
									from: fromType,
									token: v.token,
									folderId: v.folderId,
									type: v.type,
									id: v.id,
								})
							})
							if(self.fileuploadList == '') {
								self.fileuploadList == {}
							}
						}
					}
				});
			},
			//文件预览
			lookFile(fileToken, name) {
				this.looFjName = name
				this.fileToken = fileToken
				let self = this;
				$.ajax({
					type: "POST",
					url: diskUrl + "/file/previewUrl/get",
					async: false,
					cache: false,
					data: {
						type: 0,
						token: fileToken,
					},
					success: function(msg) {
						self.lookFjUrl = msg.data
					}
				});
				this.lookFj = true
			},
			//查看下一层
			lookNextFile(id, name) {
				this.fileid = id;
				this.cusUploadRecord.push(this.file_tolRecord);
				this.cusUploadData.push(this.fileuploadList)
				this.file_currpage = 1;
				this.file_pageSize = 5;
				this.fileDirectory = name; //获取文件目录名称
				$(".all_file").hide();
				$(".ul-bar").show();
				$("#titleTip").attr("title", "全部文件/" + this.fileDirectory);
				this.getFileList(1);
			},
			//返回上一层
			returnToUpperLevel() {
				this.file_tolRecord = this.cusUploadRecord.pop();
				this.fileuploadList = this.cusUploadData.pop();
				if(this.cusUploadData.length == 0) {
					this.cusUploadData = [];
					$(".all_file").show();
					$(".ul-bar").hide();
					this.fileid = this.folderid.data;
					this.getFileList(1);
				}
			},
			//查看全部
			loadAllFile() {
				$(".all_file").show();
				$(".ul-bar").hide();
				this.fileid = this.folderid.data;
				this.getFileList(1);
			},
			//根据邮箱来查询
			cusrMailAccountChange() {
				var self = this;
				$.each(this.cusr_mailAccountList, function(i, row) {
					if(self.cusr_selectMailAccount == row.account_id) { //默认选中主邮箱账号
						self.cusr_mailAccount = row.mail_account;
						self.cusr_mailAccountId = row.account_id;
						self.cusr_poporimap = row.poporimap;
					}
				})
				if(this.emailShow) { //显示收件箱列表
					this.getRMailList();
				} else { //显示发件箱列表
					this.getSMailList();
				}
			},
			//根据用户id获取邮箱
			loadMailAccountByID() {
				var self = this;
				this.getAllLinkMan();
				this.getMailAccountById({
					userId: this.user_id
				})
				this.cusr_mailAccountList = this.team_mailAccount.resultlist;
				var blog = false;
				if(this.cusr_mailAccountList.length != 0 && this.cusr_mailAccountList != "" && this.cusr_mailAccountList != null) {
					$.each(this.cusr_mailAccountList, function(i, row) {
						if(row.defalut_show == 1){   //设为默认
							blog = true;
							self.cusr_selectMailAccount = row.account_id;
							self.cusr_mailAccount = row.mail_account;
							self.cusr_mailAccountId = row.account_id;
							self.cusr_poporimap = row.poporimap;
						}else if(row.is_default == 0){ //没有默认则设置主邮箱账号为默认
							blog = true;
							self.cusr_selectMailAccount = row.account_id;
							self.cusr_mailAccount = row.mail_account;
							self.cusr_mailAccountId = row.account_id;
							self.cusr_poporimap = row.poporimap;
						}
					})
					if(blog) {
						if(self.emailShow) { //加载收件箱
							self.getRMailList();
						} else { //加载发件箱
							self.getSMailList();
						}
					} else {
						self.cusr_selectMailAccount = self.cusr_mailAccountList[0].account_id;
						self.$message({
							showClose: true,
							message: '此用户没有主邮箱账号！',
							type: 'warning'
						});
						self.rmailList = [];
						self.smailList = [];
					}
				} else {
					self.rmailList = [];
					self.smailList = [];
				}
			},
			//判断阿里巴巴插件是否开通
			checkAlibabaOpen(upId){
				let self = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cus_checkAlibabaOpen.action",
					async: true,
					cache: false,
					data: {
						token: token,
						upid: upId,
					},
					success: function (msg) {
						if(msg == 1){
							self.isAlibabaOpen = true;
						}else{
							self.isAlibabaOpen = false;
						}
					}
				});
			},
		},
		props: [
			'customer_id', 'user_id'
		],
		mounted() {
			//加载所有用户
			if(this.userlistall == '') {
				this.getUserlistAll();
			}
			//加载客户分类信息
			if(this.customerclassifyall == '') {
				this.getCustomerClassifyAll();
			}
			//加载客户来源信息
			if(this.customerfromall == '') {
				this.getCustomerFromAll();
			}
			//加载客户关系信息
			if(this.customerrelationall == '') {
				this.getCustomerRelationAll();
			}
			//加载客户状态
			if(this.customerstatusall == '') {
				this.getCustomerStatusAll();
			}
			//加载关联产品信息
			if(this.majorproductall == '') {
				this.getMajorProductAll();
			}
			//加载销售进程
			if(this.saleprocessall == '') {
				this.getSaleProcessAll();
			}
			//加载客户信息
			this.getCustomerInformation(this.customer_id);
			this.checkAlibabaOpen(this.customerInformation.user_id);
			// 标识国旗
			if(this.customerInformation.country != ''){
				let temp_flag      = this.countryArr.find(item => item.name == this.customerInformation.country);
				this.country_flag  = temp_flag != undefined ?  temp_flag.clazz : '';
			}
			//有主联系人
			if(this.customerInformation.linkman != null && this.customerInformation.linkman != '') {
				this.link_name = this.customerInformation.linkman.full_name;
			} else { //无主联系人
				this.link_name = this.customerInformation.customer_name;
			}
			//企业头像
			if(this.customerInformation.img_url !== undefined && this.customerInformation.img_url !== null && this.customerInformation.img_url != 'null'  && this.customerInformation.img_url !== '') {
					if(this.customerInformation.img_url.indexOf("http://")!=-1 || this.customerInformation.img_url.indexOf("https://")!=-1){
									this.customer_img = this.customerInformation.img_url
								}else{
									this.customer_img = backUrl + this.customerInformation.img_url
								}
			}
			var lef = this;
			//所属用户
			$.each(lef.userlistall, function(s, row) {
				if(row.user_id == lef.customerInformation.user_id) {
					lef.followup_name = row.user_name;
					return false;
				}
			});
			//获取自定义信息
			this.cusFieldList = [];
			if(this.customerInformation.customer_field != "" && this.customerInformation.customer_field != null && this.customerInformation.customer_field != undefined) {
				var defineds = this.customerInformation.customer_field.split("@@G@@");
				$.each(defineds, function(index, row1) {
					var defined = row1.split('###');
					lef.cusFieldList.push({
						name: defined[0],
						value: defined[1]
					})
				})
			}
			//获取客户附件的上传文件夹所属文件夹
			this.findCusFolderid({
				companyId: this.userinfo.company_id,
				customerId: this.customer_id,
				userId:this.user_id
			})
			this.fileid = this.folderid.data;
			lef.showDynamic(); //加载动态数据

			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
			(function() {
				let myDate = new Date();
				let h = myDate.getHours(); //获取当前小时数(0-23)
				let m = myDate.getMinutes(); //获取当前分钟数(0-59)
				let s = myDate.getSeconds();
				lef.dateTitle = h + ':' + m + ':' + s;
			})();
			$.each(lef.customerclassifyall, function(index, row) {
				if(row.id == lef.customerInformation.classify_id) {
					lef.classify_name = row.name;
					return false;
				}
			});
			$.each(lef.customerfromall, function(index, row) {
				if(row.id == lef.customerInformation.source_from_id) {
					lef.source_name = row.name;
					return false;
				}
			});
			$.each(lef.customerstatusall, function(index, row) {
				if(row.id == lef.customerInformation.customer_status) {
					lef.status_name = row.name;
					return false;
				}
			});
			$.each(lef.customerrelationall, function(index, row) {
				if(row.id == lef.customerInformation.partnership) {
					lef.relation_name = row.name;
					return false;
				}
			});
			$.each(lef.industryType, function(index, row) {
				if(row.cid == lef.customerInformation.calling) {
					lef.hangye_name = row.cname;
					return false;
				}
			});
			if(lef.customerInformation.prductid != null && lef.customerInformation.prductid != '') {
				var product_arr = lef.customerInformation.prductid.split(',');
				var pname = '未知';
				for(var i = 0; i < product_arr.length; i++) {
					$.each(this.majorproductall, function(s, row) {
						if(row.id == parseInt(product_arr[i])) {
							pname += row.name + " | ";
							return false;
						}
					});
				}
				if(pname != '未知') {
					pname = pname.replace('未知', '');
					pname = pname.substring(0, pname.length - 2);
				}
				lef.product_name = pname;
				lef.productIds=lef.customerInformation.prductid
			} else {
				lef.product_name = '未知产品';
				lef.productIds=''
			}
			lef.productIdLoading = true;
		}
	};
</script>
<style src="../../../css/crm/crmMoreInfo.css"></style>
<style src="@/css/disk/icon2.css"></style>
<style scoped>
	.lookFj {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 99999;
	}
	
	.lf-header {
		height: 54px;
		background: #20A0FF;
	}
	
	.lfh-title {
		color: #fff;
		position: absolute;
		left: 20px;
		top: 18px;
		font-size: 16px;
		max-width: 1100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.lfh-download {
		position: absolute;
		top: 18px;
		right: 100px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
	}
	
	.lfh-close {
		position: absolute;
		top: 18px;
		font-size: 16px;
		right: 15px;
		width: 60px;
		height: 30px;
		color: #fff;
		text-align: center;
		cursor: pointer;
	}
	
	.lf-content {
		position: absolute;
		top: 56px;
		bottom: 0;
		text-align: center;
	}
	
	.lfc-main {
		background-color: #fff;
		border: none;
	}
	
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity .5s;
	}
	
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
<style>
	.img_div {
		padding: 10px;
		float: left;
	}
	
	.need_p {
		border: 0.5px solid #dddddd;
		border-top-color: transparent;
		min-height: 36px;
		height: auto;
		padding: 10px;
		list-style: none;
		display: block;
		word-wrap: break-word;
		word-break: normal;
	}
	
	.need_p span {
		font-size: 12px;
	}
	/*弹框表格*/
	
	.cus-proinfo .el-dialog {
		width: 650px;
	}
	
	.cus-proinfo .el-dialog__body {
		height: auto;
		max-height: 420px;
		font-size: 12px;
	}
	
	.cus-proinfo .pro-table {
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
	
	.cus-proinfo .pro-table>tr>.re-td {
		width: 116px;
		height: 30px;
		padding-right: 10px;
		text-align: left;
		background-color: #eef1f6;
	}
	
	.cus-proinfo .pro-table>tr>td {
		width: 191px;
		padding-left: 5px;
		line-height: 33px;
		border-bottom: 1px solid #ddd;
	}
	
	.pro-table>tr>td {
		padding-left: 5px;
		line-height: 33px;
		border-bottom: 1px solid #ddd;
	}
	
	.re-td {
		width: 100px;
		height: 30px;
		padding-right: 10px;
		text-align: right;
		background-color: #eef1f6;
	}
	
	.maxpro-width {
		max-width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.maxfile-width {
		cursor: pointer;
		max-width: 190px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.maxfile-width:hover {
		color: #20A0FF;
	}
	
	.cus_dire {
		position: relative;
		background-color: #fff;
		height: 30px;
		line-height: 30px;
		color: #666;
	}
	
	.ul-bar {
		position: absolute;
		top: 0;
		font-size: 12px;
		display: none;
		background: #fff;
		height: 30px;
		line-height: 30px;
		overflow: hidden;
		white-space: nowrap;
	}
	
	.ul-bar li {
		float: left;
	}
	
	.ul-bar .line-bar {
		color: #c5d8f3;
		padding: 0 5px;
	}
</style>