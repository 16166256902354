import { render, staticRenderFns } from "./crmMoreInfo.vue?vue&type=template&id=82163e48&scoped=true&"
import script from "./crmMoreInfo.vue?vue&type=script&lang=js&"
export * from "./crmMoreInfo.vue?vue&type=script&lang=js&"
import style0 from "@/css/common.css?vue&type=style&index=0&lang=css&"
import style1 from "../../../css/crm/crmMoreInfo.css?vue&type=style&index=1&lang=css&"
import style2 from "@/css/disk/icon2.css?vue&type=style&index=2&lang=css&"
import style3 from "./crmMoreInfo.vue?vue&type=style&index=3&id=82163e48&scoped=true&lang=css&"
import style4 from "./crmMoreInfo.vue?vue&type=style&index=4&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82163e48",
  null
  
)

export default component.exports