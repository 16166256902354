<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·顶部导航栏弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div class="crm-content-title">
		<i class="icon iconfont icon-zhuye index-icon" @click="returnCrm"></i>
		<span class="crm-content-site" v-for="title in myTitle">您的位置：{{title.name}}
      <i class="refresh"></i></span>

		<!--搜索框-->
		<div class="search-bar">
			<el-input class="searchInput" size="small" v-model="searchInput" placeholder="请输入客户或者联系人相关信息..."></el-input>
			<div class="searchArrow">
				<span class="crm-search-btn search-btn" @click='searchClick'>
					<i class="icon iconfont icon-sousuo"></i>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
	export default({
		data() {
			return {
				searchInput: '',//搜索内容
			}
		},
		props: ["myTitle"],
		methods: {
			returnCrm() {
				this.$emit('returnCrm');
			},
			//搜索点击事件
			searchClick() {
				this.$emit('searchInputClick', $.trim(this.searchInput));
			}
		}
	})
</script>
<style lang="less">
	.crm-content-title {
		width: 100%;
		padding: 10px 0 10px 20px;
		line-height: 21px;
		background-color: #FCFCFC;
		display: inline-block;
		border-bottom: 1px solid #F2F2F2;
		.crm-content-site {
			float: left;
			font-size: 14px;
			padding-left: 5px;
		}
		/**-----搜索-----*/
		.search-bar {
			position: absolute;
			right: 40px;
			top: 5px;
			cursor: pointer;
		}
		.search-bar .searchInput {
			float: left;
			width: 220px;
			height: 30px;
		}
		.search-bar .searchArrow {
			position: relative;
			float: left;
			z-index: 2;
		}
		.search-bar .searchArrow .crm-search-btn {
			display: inline-block;
			line-height: 1.15;
			white-space: nowrap;
			cursor: pointer;
			height: 30px;
			width: 40px;
		}
		.search-bar .searchArrow .crm-search-btn:hover {
			background-color: #4daff7;
		}
		.search-bar .searchArrow .search-btn {
			color: #fff;
			background-color: #20a0ff;
			border-color: #20a0ff;
			text-align: center;
		}
		.search-bar .searchArrow .search-btn .icon-sousuo {
			font-size: 26px;
		}
		.search .el-input--small .el-input__inner {
			margin-top: 0px;
		}
	}
</style>